/* eslint-disable @typescript-eslint/no-unused-vars */
import {acquireAuthResult, msalInstance} from '../../index';
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {replace} from 'connected-react-router';
import routePaths from '../../routePaths';
import {ClientAdminUsers, FormStatus, UserData, UserRole} from '../../types';
import {
  inviteUserAction,
  reactivateUserAction,
  removeUserDataAction,
  resendInviteUserAction,
  setAdminData,
  setClientAdminUsersFormStatus,
  setClientAdminUsersIsReinvitePending,
  setClientAdminUsersIsRequestPending,
  updateUserData,
  updateUserDataAction,
} from '../../reducers/clientAdminUsersSlice';
import {errorHandlerAction} from '../../actions';
import {
  getClientAdminUsersData,
  inviteUser,
  reactivateUser,
  removeUser,
  resendUserInvite,
  updateUser,
} from '../../api/getClientAdminUsers';
import {usersResponseErrorResolver} from '../../utils';
import {globalInfoMessage} from '../../constants';
import {setGlobalInformation} from '../../reducers/globalInformationSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import i18n from '../../i18n';
import {RootState} from '../../store';

export const getClientAdminUsersSaga =
  function* getClientAdminUsersSaga(): any {
    // Feature Flags
    const beac333EnableUsers = yield select(
      (state: RootState) => state.featureFlags.data.beac333EnableUsers
    );
    if (beac333EnableUsers) {
      window.location.replace(`/admin/users`);
    }

    const accountInfo = msalInstance.getAllAccounts();

    if (accountInfo?.length > 0) {
      const userRole = accountInfo[0].idTokenClaims?.extension_ApplicationRole;
      if (userRole !== UserRole.ClientAdmin) {
        return yield put(replace(routePaths.HOME));
      }

      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const adminResponse: ClientAdminUsers = yield call(
          getClientAdminUsersData,
          accessToken
        );
        yield put(setAdminData(adminResponse));
      } catch (err) {
        console.warn(err);
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const updateUserSaga = function* updateUserSaga({
  payload,
}: {
  payload: UserData;
  type: string;
}) {
  if (payload) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setClientAdminUsersFormStatus(FormStatus.Pending));
      const response: UserData = yield call(
        updateUser,
        accessToken,
        payload.id,
        payload
      );

      yield put(updateUserData(response));
      yield put(setClientAdminUsersFormStatus(FormStatus.Ready));
    } catch (error: any) {
      const responseError = usersResponseErrorResolver(error);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
      yield put(setClientAdminUsersFormStatus(FormStatus.Ready));
      console.warn(error);
    }
  }
};

export const removeUserSaga = function* removeUserSaga({
  payload,
}: {
  payload: UserData;
  type: string;
}) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    yield put(setClientAdminUsersIsRequestPending(true));

    const response: UserData = yield call(removeUser, accessToken, payload.id);

    yield put(updateUserData(response));
    yield put(setClientAdminUsersIsRequestPending(false));
  } catch (err: any) {
    const responseError = usersResponseErrorResolver(err);
    if (responseError) {
      yield put(setGlobalInformation(responseError));
    }
    yield put(setClientAdminUsersIsRequestPending(false));
    console.warn(err);
  }
};

export const inviteUserSaga = function* inviteUserSaga({
  payload,
}: {
  payload: UserData;
  type: string;
}) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    yield put(setClientAdminUsersFormStatus(FormStatus.Pending));
    yield call(inviteUser, accessToken, payload);
    yield put(setClientAdminUsersFormStatus(FormStatus.Success));

    // dispatch invitation sent global information modal
    yield put(setGlobalInformation(globalInfoMessage()));
  } catch (err: any) {
    const responseError = usersResponseErrorResolver(err);
    if (responseError) {
      yield put(setGlobalInformation(responseError));
    }
    yield put(setClientAdminUsersFormStatus(FormStatus.Ready));
    console.warn(err);
  }
};

export const reInviteUserSaga = function* reInviteUserSaga({
  payload,
}: {
  payload: {id: string};
  type: string;
}) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    yield put(setClientAdminUsersIsReinvitePending(true));
    const response: UserData = yield call(
      resendUserInvite,
      accessToken,
      payload
    );
    yield put(updateUserData(response));
    yield put(setClientAdminUsersIsReinvitePending(false));

    const reinviteSuccessInfoMsg = globalInfoMessage();

    yield put(setGlobalInformation(reinviteSuccessInfoMsg));
  } catch (err: any) {
    const responseError = usersResponseErrorResolver(err);
    if (responseError) {
      yield put(setGlobalInformation(responseError));
    }
    yield put(setClientAdminUsersIsReinvitePending(false));
    console.warn(err);
  }
};

export const reactivateUserSaga = function* reactivateUserSaga({
  payload,
}: PayloadAction<{id: string}>) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    yield put(setClientAdminUsersIsReinvitePending(true));
    const response: UserData = yield call(reactivateUser, accessToken, payload);

    yield put(updateUserData(response));
    yield put(setClientAdminUsersIsReinvitePending(false));
    const reactivateSuccessInfoMsg = {
      ...globalInfoMessage(),
      message: i18n.t('Admin.UserAdministration.Notification.Reactivated'),
    };

    yield put(setGlobalInformation(reactivateSuccessInfoMsg));
  } catch (error: any) {
    const responseError = usersResponseErrorResolver(error);
    if (responseError) {
      yield put(setGlobalInformation(responseError));
    }
    yield put(setClientAdminUsersIsReinvitePending(false));
    console.warn(error);
  }
};

export const clientAdminUsersSagaWatcher =
  function* clientAdminUsersSagaWatcher() {
    yield all([
      takeEvery(updateUserDataAction.type, updateUserSaga),
      takeEvery(removeUserDataAction.type, removeUserSaga),
      takeEvery(inviteUserAction.type, inviteUserSaga),
      takeEvery(resendInviteUserAction.type, reInviteUserSaga),
      takeEvery(reactivateUserAction.type, reactivateUserSaga),
    ]);
  };
