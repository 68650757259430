import {all, takeLatest, select} from 'redux-saga/effects';
import {msalInstance} from '../../index';
import TagManager from 'react-gtm-module';
import {setUserAnalyticsDataAction} from '../../reducers/userProfileServiceSlice';
import {UserProfileData} from '../../types';
import {RootState} from '../../store';

export const userAnalyticsSaga = function* userAnalyticsSaga(): any {
  const accountInfo = msalInstance.getAllAccounts();
  const userProfileData: UserProfileData | null = yield select(
    (state: RootState) => state.userProfile.data
  );
  if (accountInfo?.length > 0 && userProfileData) {
    const sub = accountInfo[0].idTokenClaims?.sub;

    const primaryUserProps = {
      firstName: accountInfo[0].idTokenClaims?.given_name || '',
      persona: userProfileData.persona || '',
      domain: userProfileData.domain,
      ...(userProfileData.fundIds
        ? {
            fundIds: userProfileData.fundIds.join(','),
          }
        : {}),
      ...(userProfileData.portfolioIds
        ? {
            portfolioIds: userProfileData.portfolioIds.join(','),
          }
        : {}),
    };

    const secondaryUserProps = {
      event: 'virtualPageview',
      clientName: userProfileData.clientName,
      userId: userProfileData.id,
      email: userProfileData.email,
      userRole: userProfileData.userRole,
      accountType: userProfileData.accountType,
      ...(userProfileData.numberOfFunds || userProfileData.numberOfFunds === 0
        ? {
            numberOfFunds: userProfileData.numberOfFunds,
          }
        : {}),
      ...(userProfileData.numberOfCompanies ||
      userProfileData.numberOfCompanies === 0
        ? {
            numberOfCompanies: userProfileData.numberOfCompanies,
          }
        : {}),
      ...(userProfileData.numberOfUsers || userProfileData.numberOfUsers === 0
        ? {
            numberOfUsers: userProfileData.numberOfUsers,
          }
        : {}),
      ...(userProfileData.subscriptions
        ? {
            subscriptions: userProfileData.subscriptions,
          }
        : {}),
      ...(userProfileData.subscriptionStart
        ? {
            subscriptionStart: userProfileData.subscriptionStart,
          }
        : {}),
      ...(userProfileData.created
        ? {
            created: userProfileData.created,
          }
        : {}),
      ...(userProfileData.reportingCompanyEncrypted
        ? {
            reportingCompany: userProfileData.reportingCompanyEncrypted,
          }
        : {}),
    };

    try {
      //@ts-ignore
      window.Appcues.identify(sub, {
        ...primaryUserProps,
      });
      //@ts-ignore
      window.heap.identify(sub);
      //@ts-ignore
      window.heap.addUserProperties({
        firstName: primaryUserProps.firstName,
        email: secondaryUserProps.email,
        persona: primaryUserProps.persona,
        domain: primaryUserProps.domain,
      });
      // segment
      //@ts-ignore
      window.analytics.identify(sub, {
        ...primaryUserProps,
        ...secondaryUserProps,
      });

      TagManager.dataLayer({
        dataLayer: {
          ...secondaryUserProps,
        },
        dataLayerName: 'PageDataLayer',
      });
    } catch (err) {
      console.warn(err);
    }
  }
  yield 0;
};

export const userAnalyticsSagaWatcher = function* userAnalyticsSagaWatcher() {
  yield all([takeLatest(setUserAnalyticsDataAction.type, userAnalyticsSaga)]);
};
