import {Func} from './func';
import {FormStatus} from './enums';

export type DataCellError = {
  cellId: string;
  firstError: boolean;
  message: string;
};

export type ReportingTableCellData = {
  type: string;
  format: string;
  constrains: string[];
  value: any;
  submitted?: boolean | null;
  mandatory?: boolean | null;
  description?: string | null;
  errorMessage?: DataCellError;
  isLocked?: null | boolean;
};

export type ReportingTableCell = {
  type: string;
  id: string;
  rowId: string;
  columnId: string;
  afterSeparator: boolean | null;
  mandatory?: boolean;
  isVisible?: boolean;
  disabled?: boolean;
  selected?: boolean;
  meta?: any;
  data: ReportingTableCellData | null;
};

export type QuestionnaireRowOptions = {
  currency: string | null;
  answers: Array<{
    id: string;
    label: string;
    value: string;
  }> | null;
} | null;

export type ReportingTableRow = {
  type: string;
  id: string;
  groupId?: string | null;
  meta: any;
  questionnaire?: QuestionnaireRowOptions;
  cells: ReportingTableCell[];
};

export enum ReportingTableTemplateType {
  ActualsReportingTemplate = 'ActualsReportingTemplate',
  ForecastReportingTemplate = 'ForecastReportingTemplate',
  ESGReportingTemplate = 'ESGReportingTemplate',
}

export enum ForecastReportingInterval {
  QuarterlyForecast = 'Quarterly',
  SixMonthForecast = '6 month forecast',
  TwelveMonthForecast = '12 month forecast',
}

export enum ReportingTableStructureType {
  Table = 'table',
  Questionnaire = 'questionnaire',
}

export type ReportingTableData = {
  type: string; // ActualsReportingTemplate | ForecastReportingTemplate | CustomReportingTemplate etc.
  structureType: ReportingTableStructureType;
  id: string;
  name: string;
  headerLength: number;
  rowLength: number;
  meta: any;

  isReadOnly: boolean;
  statusRows: ReportingTableRow[];
  dataRows: ReportingTableRow[];
  collapseReset?: boolean;
};

export type ActiveReportingTableRows = {
  statusRows: ReportingTableRow[];
  dataRows: ReportingTableRow[];
};

export type ReportingTableOnGetReportingPeriodDocuments = Func<
  [columnId: string],
  void
>;
export type ReportingTableOnColumnUnlock = Func<[columnId: string], void>;
export type ReportingTableOnColumnCancel = Func<[columnId: string], void>;
export type ReportingTableOnColumnSubmit = Func<
  [columnId: string, submitted?: boolean],
  void
>;

export type ReportingTableOnCellSubmit = Func<[cell: ReportingTableCell], void>;

export type ReportingTableOnSetDataCellErrorMessage = Func<
  [
    payload: {
      id: string;
      value: number | null;
      error: DataCellError | undefined;
    }
  ],
  void
>;

export type ReportingTableProps = {
  reportingData: ReportingTableData;
  activeRowsData: ActiveReportingTableRows;
  readOnly: boolean;
  isHistorical?: boolean;
  headerLength: number;
  rowLength: number;
  onCollapsed: Func<any, void>;
  onPrevious: Func<any, void>;
  onNext: Func<any, void>;
  onColumnSubmit?: ReportingTableOnColumnSubmit;
  onColumnUnlock?: ReportingTableOnColumnUnlock;
  onCellSubmit: ReportingTableOnCellSubmit;
  onColumnCancel?: ReportingTableOnColumnCancel;
  onGetReportingPeriodDocuments: ReportingTableOnGetReportingPeriodDocuments;
  onSetDataCellErrorMessage: ReportingTableOnSetDataCellErrorMessage;
  onCopy?: Func<any, void>;
  onPaste?: Func<any, void>;
  columnUnlockingStatus?: FormStatus;
  activeUnlockedColumnId?: string | null;
};

export enum ReportingTableRowTypes {
  StatusHeaderRow = 'StatusHeaderRow',
  ForecastStatusHeaderRow = 'ForecastStatusHeaderRow',
  StatusSubmissionDateRow = 'StatusSubmissionDateRow',
  StatusUpdateDateRow = 'StatusUpdateDateRow',
  StatusDocumentsRow = 'StatusDocumentsRow',
  StatusPeriodDateRow = 'StatusPeriodDateRow',
  GroupRow = 'GroupRow',
  GroupHeaderRow = 'GroupHeaderRow',
  GroupSubHeaderRow = 'GroupSubHeaderRow',
}

export enum ReportingTableCellTypes {
  EmptyCell = 'EmptyCell',
  SeparatorCell = 'SeparatorCell',
  StatusCell = 'StatusCell',
  ForecastStatusCell = 'ForecastStatusCell',
  StatusSubmissionDateCell = 'StatusSubmissionDateCell',
  StatusPeriodDateCell = 'StatusPeriodDateCell',
  StatusDocumentsCell = 'StatusDocumentsCell',
  TemplateHeaderCell = 'TemplateHeaderCell',
  GroupHeaderCell = 'GroupHeaderCell',
  GroupSubHeaderCell = 'GroupSubHeaderCell',
  HeaderCell = 'HeaderCell',
  MandatoryIndicatorCell = 'MandatoryIndicatorCell',
  DataCell = 'DataCell',
  TextDataCell = 'TextDataCell',
  HistoricalReportDateCell = 'HistoricalReportDateCell',
  StatusUpdateDateCell = 'StatusUpdateDateCell',
}

export enum ReportingTableColumnStatusLabel {
  Submitted = 'Submitted',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Draft = 'Draft',
  Due = 'Due',
  LastUpdated = 'Last updated',
}

export enum ReportingTableErrorMessages {
  isMandatory = 'This field is mandatory',
  dotsOnlyForDecimals = 'Use dots only for decimals',
  numbersOnly = 'Please input numbers only',
}

export type SubmitMultipleCellsPayload = {
  cells: Array<{
    id: string;
    value: number | null;
  }>;
};

export type ReportingTableSubmitActionType =
  | 'submit'
  | 'unlock'
  | 'update'
  | 'cancel';
