import {
  DataCellError,
  FormStatus,
  ReportingTableCell,
  ReportingTableCellTypes,
  ReportingTableColumnStatusLabel,
  ReportingTableData,
  ReportingTableErrorMessages,
  ReportingTableRow,
  ReportingTableRowTypes,
  ReportingTableSubmitActionType,
  ReportingTableTemplateType,
} from '../types';
import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  disableColumnSubmitButton,
  // disableForecastSubmitButton,
  getActiveOffset,
  getCurrentForecastInterval,
  paginateRowsData,
  updateDataCellErrorMessage,
  updateInvalidCells,
} from '../utils';

export const submitDataCellAction = createAction(
  'reporting-table/submit-data-cell',
  (cell: ReportingTableCell) => ({
    payload: {
      cell,
    },
  })
);

export const submitColumnsData = createAction(
  'reporting-table/submit-columns-data',
  (columnIds: string[]) => {
    return {
      payload: {
        columnIds,
      },
    };
  }
);

export const submitColumnData = createAction(
  'reporting-table/submit-column-data',
  (
    columnId: string,
    reportingTableTemplateType: ReportingTableTemplateType
  ) => {
    return {
      payload: {
        columnId,
        reportingTableTemplateType,
      },
    };
  }
);

export const updateColumnDataAction = createAction(
  'reporting-table/update-column-data',
  (
    columnId: string,
    reportingTableTemplateType: ReportingTableTemplateType
  ) => {
    return {
      payload: {
        columnId,
        reportingTableTemplateType,
      },
    };
  }
);

export const unlockColumnAction = createAction(
  'reporting-table/unlock-column',
  (
    columnId: string,
    reportingTableTemplateType: ReportingTableTemplateType
  ) => {
    return {
      payload: {
        columnId,
        reportingTableTemplateType,
      },
    };
  }
);

export const submitMultipleCellsData = createAction(
  'reporting-table/submit-multiple-cells-data',
  (
    dataRows: ReportingTableRow[],
    clipboardData: any,
    cellId: string,
    reportingTableTemplateType: ReportingTableTemplateType
  ) => {
    return {
      payload: {
        dataRows,
        clipboardData,
        cellId,
        reportingTableTemplateType,
      },
    };
  }
);

export const validateColumnsAndShowNext = createAction(
  'reporting-table/validate-columns-and-show-next',
  (
    data: ReportingTableData,
    // columnIds: string[],
    collapsed: boolean,
    activeOffset: number,
    justVisible: boolean
  ) => {
    return {
      payload: {
        data,
        // columnIds,
        collapsed,
        activeOffset,
        justVisible,
      },
    };
  }
);

export const validateColumnsAndSubmit = createAction(
  'reporting-table/validate-columns-and-submit',
  (data: ReportingTableData) => {
    return {
      payload: {
        data,
      },
    };
  }
);

export const cancelUpdateColumnAction = createAction(
  'reporting-table/cancel-update-column',
  (
    columnId: string,
    reportingTableTemplateType: ReportingTableTemplateType
  ) => {
    return {
      payload: {
        columnId,
        reportingTableTemplateType,
      },
    };
  }
);

export const exportReportingDataAction = createAction(
  'reporting-table/export-data',
  (workflowId: string) => {
    return {
      payload: {
        workflowId,
      },
    };
  }
);

interface ReportingTableSlice {
  data: ReportingTableData[] | null;
  activeSubmitColumnId: string | null;
  activeUpdateColumnId: string | null;
  activeCanceledColumnId: string | null;
  activeUnlockedColumnId: string | null;
  columnSubmittingStatus: FormStatus;
  columnUnlockingStatus: FormStatus;
  exportPending: boolean;
}

const initialState: ReportingTableSlice = {
  data: null,
  activeSubmitColumnId: null,
  columnSubmittingStatus: FormStatus.Ready,
  columnUnlockingStatus: FormStatus.Ready,
  activeUpdateColumnId: null,
  activeCanceledColumnId: null,
  activeUnlockedColumnId: null,
  exportPending: false,
};

const reportingTableSlice = createSlice({
  name: 'reporting-table',
  initialState,
  reducers: {
    setReportingTableData(
      state: ReportingTableSlice,
      action: PayloadAction<ReportingTableData[]>
    ) {
      state.data = action.payload;
      if (state.data) {
        state.data = state.data.map(data => {
          const reportingInterval = getCurrentForecastInterval(data.statusRows);
          return {
            ...data,
            statusRows: paginateRowsData(
              data.statusRows,
              data.headerLength,
              data.rowLength,
              getActiveOffset(data),
              true,
              data.type as ReportingTableTemplateType,
              reportingInterval
            ),
            dataRows: paginateRowsData(
              data.dataRows,
              data.headerLength,
              data.rowLength,
              getActiveOffset(data),
              true,
              data.type as ReportingTableTemplateType,
              reportingInterval
            ),
          };
        });
      }
    },
    setHistoricalReportingTableData(
      state: ReportingTableSlice,
      action: PayloadAction<ReportingTableData>
    ) {
      if (state.data) {
        state.data = state.data.map(data => {
          if (data.type !== action.payload.type) return data;
          const reportingInterval = getCurrentForecastInterval(
            action.payload.statusRows
          );

          return {
            ...action.payload,
            statusRows: paginateRowsData(
              action.payload.statusRows,
              action.payload.headerLength,
              action.payload.rowLength,
              getActiveOffset(action.payload),
              true,
              action.payload.type as ReportingTableTemplateType,
              reportingInterval
            ),
            dataRows: paginateRowsData(
              action.payload.dataRows,
              action.payload.headerLength,
              action.payload.rowLength,
              getActiveOffset(action.payload),
              true,
              action.payload.type as ReportingTableTemplateType,
              reportingInterval
            ),
          };
        });
      }
    },
    clearReportingTableData(state: ReportingTableSlice) {
      state.data = null;
    },
    setCollapseReset(
      state: ReportingTableSlice,
      action: PayloadAction<{
        collapseReset?: boolean | undefined;
        reportingTableTemplateType: ReportingTableTemplateType;
      }>
    ) {
      if (state.data) {
        state.data = state.data.map(data => {
          if (data.type !== action.payload.reportingTableTemplateType)
            return data;
          return {
            ...data,
            collapseReset: action.payload.collapseReset,
          };
        });
      }
    },
    setForecastIsOnFinalPage(
      state: ReportingTableSlice,
      action: PayloadAction<{
        isOnFinalPage: boolean;
        reportingTableTemplateType: ReportingTableTemplateType;
      }>
    ) {
      if (state?.data) {
        state.data = state.data.map(data => {
          if (data.type !== action.payload.reportingTableTemplateType)
            return data;
          return {
            ...data,
            statusRows: data.statusRows.map((row: ReportingTableRow) => {
              if (row.type === ReportingTableRowTypes.StatusHeaderRow) {
                const updatedCells = row.cells.map(
                  (cell: ReportingTableCell) => {
                    if (
                      cell.type ===
                        ReportingTableCellTypes.ForecastStatusCell &&
                      !!cell?.data?.value
                    ) {
                      return {
                        ...cell,
                        data: {
                          ...cell.data,
                          value: {
                            ...cell.data.value,
                            isOnFinalPage: action.payload.isOnFinalPage,
                          },
                        },
                      };
                    }

                    return cell;
                  }
                );

                return {
                  ...row,
                  cells: updatedCells,
                };
              }

              return row;
            }),
          };
        });
      }
    },
    setDataCellData(
      state: ReportingTableSlice,
      action: PayloadAction<{
        rowId: string;
        cellId: string;
        value: number;
      }>
    ) {
      if (state.data) {
        state.data = state.data.map(data => {
          return {
            ...data,
            dataRows: data.dataRows.map(dataRow => {
              if (action.payload.rowId !== dataRow.id) return dataRow;
              return {
                ...dataRow,
                cells: dataRow.cells.map(cell => {
                  if (cell.id !== action.payload.cellId) return cell;
                  if (!cell.data) return cell;
                  return {
                    ...cell,
                    data: {
                      ...cell.data,
                      value: action.payload.value,
                    },
                  };
                }),
              };
            }),
          };
        });
      }
    },
    setActiveSubmitColumnId(
      state: ReportingTableSlice,
      action: PayloadAction<string | null>
    ) {
      state.activeSubmitColumnId = action.payload;
    },
    setActiveUpdateColumnId(
      state: ReportingTableSlice,
      action: PayloadAction<string | null>
    ) {
      state.activeUpdateColumnId = action.payload;
    },
    setActiveReportingTableRowsData(
      state: ReportingTableSlice,
      action: PayloadAction<{
        reportingData: ReportingTableData;
        collapsed: boolean;
        activeOffset: number;
      }>
    ) {
      if (state.data) {
        state.data = state.data.map(data => {
          if (data.type !== action.payload.reportingData.type) return data;

          // Always reset error messages before pagination
          const dataRowsWithoutErrors = updateInvalidCells(data.dataRows);

          const reportingInterval = getCurrentForecastInterval(data.statusRows);

          return {
            ...data,
            statusRows: paginateRowsData(
              action.payload.reportingData.statusRows,
              action.payload.reportingData.headerLength,
              action.payload.reportingData.rowLength,
              action.payload.activeOffset,
              action.payload.collapsed,
              action.payload.reportingData.type as ReportingTableTemplateType,
              reportingInterval
            ),
            dataRows: paginateRowsData(
              dataRowsWithoutErrors,
              action.payload.reportingData.headerLength,
              action.payload.reportingData.rowLength,
              action.payload.activeOffset,
              action.payload.collapsed,
              action.payload.reportingData.type as ReportingTableTemplateType,
              reportingInterval
            ),
          };
        });
      }
    },
    validateColumnCellData(
      state: ReportingTableSlice,
      action: PayloadAction<{
        columnId: string;
        reportingTableTemplateType: ReportingTableTemplateType;
        submitted?: boolean;
      }>
    ) {
      let cellsWithError: Array<string> = [];

      if (state?.data) {
        state.data = state.data.map(data => {
          if (data.type !== action.payload.reportingTableTemplateType)
            return data;
          return {
            ...data,
            dataRows: data.dataRows.map(row => ({
              ...row,
              cells: row.cells.map(cell => {
                if (
                  cell.columnId !== action.payload.columnId ||
                  cell.type !== ReportingTableCellTypes.DataCell ||
                  !cell.data?.mandatory ||
                  (cell.data?.value !== null && cell.data?.value !== '')
                )
                  return {
                    ...cell,
                    errorMessage: undefined,
                  };

                cellsWithError.push(cell.id);

                const data = !!cell?.data
                  ? {
                      ...cell.data,
                      errorMessage: {
                        cellId: cell.id,
                        firstError: cellsWithError.length === 1,
                        message: ReportingTableErrorMessages.isMandatory,
                      },
                    }
                  : null;
                return {
                  ...cell,
                  data,
                };
              }),
            })),
          };
        });

        if (cellsWithError.length === 0) {
          if (action.payload.submitted) {
            state.activeUpdateColumnId = action.payload.columnId;
            return;
          }
          state.activeSubmitColumnId = action.payload.columnId;
        }
      }
    },
    setColumnSubmittingStatus(
      state: ReportingTableSlice,
      action: PayloadAction<FormStatus>
    ) {
      state.columnSubmittingStatus = action.payload;
    },
    setColumnUnlockingStatus(
      state: ReportingTableSlice,
      action: PayloadAction<FormStatus>
    ) {
      state.columnUnlockingStatus = action.payload;
    },
    setDataCellErrorMessage(
      state: ReportingTableSlice,
      action: PayloadAction<{
        id: string;
        value: number | null;
        error: DataCellError | undefined;
      }>
    ) {
      if (state?.data) {
        state.data = state.data.map(data => ({
          ...data,
          dataRows: updateDataCellErrorMessage(
            data.dataRows,
            action.payload.id,
            action.payload.value,
            action.payload.error
          ),
        }));
      }
    },
    updateColumnCellData(
      state: ReportingTableSlice,
      action: PayloadAction<{
        columnId: string;
        submitted: boolean;
        isLocked: boolean;
        reportingTableTemplateType: ReportingTableTemplateType;
        submitActionType: ReportingTableSubmitActionType;
        dateLastUpdated?: string;
        cells?: {id: string; value: number | null}[];
      }>
    ) {
      const updatedStatusRows = (
        rows: ReportingTableRow[]
      ): ReportingTableRow[] => {
        return rows.map((row: ReportingTableRow) => {
          if (row.type === ReportingTableRowTypes.StatusSubmissionDateRow) {
            const rowCells = row.cells.map((cell: ReportingTableCell) => {
              if (cell.columnId === action.payload.columnId) {
                return {
                  ...cell,
                  data: {
                    ...cell.data,
                    submitted: action.payload.submitted,
                    isLocked: action.payload.isLocked,
                    value:
                      action.payload.submitActionType === 'submit'
                        ? `${ReportingTableColumnStatusLabel.Submitted} ${
                            // After column submission we should display submission date instead of due date
                            new Date().toISOString()
                          }`
                        : cell.data?.value,
                  },
                };
              }
              return cell;
            });

            return {...row, cells: rowCells} as ReportingTableRow;
          }

          // Updating UpdateDateRowCell
          if (row.type === ReportingTableRowTypes.StatusUpdateDateRow) {
            const rowCells = row.cells.map((cell: ReportingTableCell) => {
              if (cell.columnId === action.payload.columnId) {
                return {
                  ...cell,
                  data: {
                    ...cell.data,
                    submitted: action.payload.submitted,
                    isLocked: action.payload.isLocked,
                    value: action.payload.dateLastUpdated
                      ? new Date(action.payload.dateLastUpdated).toISOString()
                      : cell.data?.value,
                  },
                };
              }
              return cell;
            });

            return {...row, cells: rowCells} as ReportingTableRow;
          }

          if (row.type === ReportingTableRowTypes.StatusHeaderRow) {
            const rowCells = row.cells.map(cell => {
              if (cell.columnId === action.payload.columnId) {
                return {
                  ...cell,
                  data: {
                    ...cell.data,
                    submitted: action.payload.submitted,
                    isLocked: action.payload.isLocked,
                    value: ReportingTableColumnStatusLabel.Submitted,
                  },
                };
              }
              return cell;
            });

            return {...row, cells: rowCells} as ReportingTableRow;
          }

          return row;
        });
      };

      const updatedDataRows = (
        rows: ReportingTableRow[]
      ): ReportingTableRow[] => {
        return rows.map(row => {
          if (row.type === ReportingTableRowTypes.GroupRow) {
            const rowCells = row.cells.map(cell => {
              if (cell.columnId === action.payload.columnId) {
                const newValue = action.payload.cells?.find(
                  item => item.id === cell.id
                )?.value;
                return {
                  ...cell,
                  data: {
                    ...cell.data,
                    value:
                      newValue !== undefined
                        ? {
                            isUpdated: true,
                            updateValue: newValue,
                          }
                        : cell.data?.value,
                    isLocked: action.payload.isLocked,
                    submitted: action.payload.submitted,
                    errorMessage: {
                      cellId: cell.id,
                      firstError: false,
                      message: '',
                    },
                  },
                };
              }
              return cell;
            });

            return {...row, cells: rowCells} as ReportingTableRow;
          }

          return row;
        });
      };

      if (state.data) {
        state.data = state.data.map(data => {
          return {
            ...data,
            dataRows: updatedDataRows(data.dataRows),
            statusRows: updatedStatusRows(data.statusRows),
          };
        });
      }
    },
    setColumnSubmitButtonAsDisabled(
      state: ReportingTableSlice,
      action: PayloadAction<{columnIds: Array<string>; disabled: boolean}>
    ) {
      if (state.data) {
        state.data = state.data.map(data => {
          if (
            data.type === ReportingTableTemplateType.ActualsReportingTemplate ||
            data.type ===
              ReportingTableTemplateType.ForecastReportingTemplate ||
            data.type === ReportingTableTemplateType.ESGReportingTemplate
          ) {
            return {
              ...data,
              statusRows: disableColumnSubmitButton(
                data.statusRows,
                action.payload.columnIds,
                action.payload.disabled
              ),
            };
          }

          // if (
          //   data.type === ReportingTableTemplateType.ForecastReportingTemplate
          // ) {
          //   return {
          //     ...data,
          //     statusRows: disableForecastSubmitButton(
          //       data.statusRows,
          //       action.payload.disabled
          //     ),
          //   };
          // }

          return data;
        });
      }
    },
    saveReportingDataFromClipboard(
      state: ReportingTableSlice,
      action: PayloadAction<{
        dataRows: ReportingTableRow[];
        reportingTableTemplateType: ReportingTableTemplateType;
      }>
    ) {
      if (state.data) {
        state.data = state.data.map(data => {
          if (data.type !== action.payload.reportingTableTemplateType)
            return data;
          return {
            ...data,
            dataRows: action.payload.dataRows,
          };
        });
      }
    },
    validateColumnsData(
      state: ReportingTableSlice,
      action: PayloadAction<{
        dataRows: ReportingTableRow[];
        reportingTableTemplateType: ReportingTableTemplateType;
      }>
    ) {
      if (state.data) {
        state.data = state.data.map(data => {
          if (data.type !== action.payload.reportingTableTemplateType)
            return data;
          return {
            ...data,
            dataRows: action.payload.dataRows,
          };
        });
      }
    },
    setActiveCanceledColumnId(
      state: ReportingTableSlice,
      action: PayloadAction<string | null>
    ) {
      state.activeCanceledColumnId = action.payload;
    },
    setActiveUnlockedColumnId(
      state: ReportingTableSlice,
      action: PayloadAction<string | null>
    ) {
      state.activeUnlockedColumnId = action.payload;
    },
    setExportPending(
      state: ReportingTableSlice,
      action: PayloadAction<boolean>
    ) {
      state.exportPending = action.payload;
    },
    setReportingTableUploadedFilesCount(
      state: ReportingTableSlice,
      action: PayloadAction<string[] | []>
    ) {
      action.payload.forEach((columnId: string) => {
        state.data &&
          state.data[0].statusRows.forEach(row => {
            if (row.type === ReportingTableRowTypes.StatusDocumentsRow) {
              const cell = row.cells.find(cell => cell.columnId === columnId);

              if (typeof cell?.data?.value === 'number') {
                cell.data.value += 1;
              }
            }
          });
      });
    },
    updateSubmittedColumnsCellData(
      state: ReportingTableSlice,
      action: PayloadAction<{
        columns: string[];
      }>
    ) {
      const updatedStatusRows = (
        rows: ReportingTableRow[]
      ): ReportingTableRow[] => {
        return rows.map((row: ReportingTableRow) => {
          if (row.type === ReportingTableRowTypes.StatusHeaderRow) {
            const rowCells = row.cells.map(cell => {
              if (action.payload.columns.includes(cell.columnId)) {
                return !!cell?.data?.value
                  ? {
                      ...cell,
                      data: {
                        ...cell.data,
                        submitted: true,
                        value: {
                          ...cell.data.value,
                          status: ReportingTableColumnStatusLabel.Submitted,
                          date: new Date().toISOString(),
                        },
                      },
                    }
                  : cell;
              }
              return cell;
            });

            return {...row, cells: rowCells} as ReportingTableRow;
          }

          return row;
        });
      };

      const updatedDataRows = (
        rows: ReportingTableRow[]
      ): ReportingTableRow[] => {
        return rows.map(row => {
          if (row.type === ReportingTableRowTypes.GroupRow) {
            const rowCells = row.cells.map(cell => {
              if (action.payload.columns.includes(cell.columnId)) {
                return {
                  ...cell,
                  data: {
                    ...cell.data,
                    submitted: true,
                    errorMessage: {
                      cellId: cell.id,
                      firstError: false,
                      message: '',
                    },
                  },
                };
              }
              return cell;
            });

            return {...row, cells: rowCells} as ReportingTableRow;
          }

          return row;
        });
      };

      if (state.data) {
        state.data = state.data.map(data => {
          if (
            data.type !== ReportingTableTemplateType.ForecastReportingTemplate
          )
            return data;
          return {
            ...data,
            statusRows: updatedStatusRows(data.statusRows),
            dataRows: updatedDataRows(data.dataRows),
          };
        });
      }
    },
  },
});

export default reportingTableSlice.reducer;
export const {
  setReportingTableData,
  setHistoricalReportingTableData,
  clearReportingTableData,
  setCollapseReset,
  setForecastIsOnFinalPage,
  setColumnSubmitButtonAsDisabled,
  setColumnSubmittingStatus,
  setColumnUnlockingStatus,
  setDataCellErrorMessage,
  setActiveSubmitColumnId,
  setDataCellData,
  setActiveReportingTableRowsData,
  updateColumnCellData,
  validateColumnCellData,
  saveReportingDataFromClipboard,
  validateColumnsData,
  updateSubmittedColumnsCellData,
  setActiveUpdateColumnId,
  setActiveCanceledColumnId,
  setActiveUnlockedColumnId,
  setReportingTableUploadedFilesCount,
  setExportPending,
} = reportingTableSlice.actions;
