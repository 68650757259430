import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {useAppDispatch} from './hooks/useReduxHooks';
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk';
import {ConnectedRouter} from 'connected-react-router';
import {renderRoutes} from 'react-router-config';
import TagManager from 'react-gtm-module';
import store, {history} from './store';
import config from './config';
// import {aadLoginSuccessAction} from './reducers/authSlice';
import routes from './routes';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {setFeatureFlags} from './reducers/featureFlagsSlice';
import {manageAccessTokenCookie} from './utils/cookie';

// i18next
import './i18n';

// MSAL imports
import {PublicClientApplication, EventType} from '@azure/msal-browser';
import {msalConfig} from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    // store.dispatch(aadLoginSuccessAction());
  }
});

export const acquireTokenRedirectRequest = async msalInstance => {
  await msalInstance.handleRedirectPromise();
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  const request = {
    scopes: [config.API_SCOPES],
    account: activeAccount || accounts[0],
  };
  try {
    const response = await msalInstance.acquireTokenRedirect(request);
    return response;
  } catch (err) {
    console.warn(err);
  }
};

export const logoutRedirectRequest = async msalInstance => {
  await msalInstance.handleRedirectPromise();
  try {
    await msalInstance.logoutRedirect();
  } catch (err) {
    console.warn(err);
  }
};

export const acquireAuthResult = async msalInstance => {
  await msalInstance.handleRedirectPromise();
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    throw new Error('Error acquiring auth result, no account found');
  }
  const request = {
    scopes: [config.API_SCOPES],
    account: activeAccount || accounts[0],
  };
  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    manageAccessTokenCookie(authResult);
    return authResult;
  } catch (err) {
    await acquireTokenRedirectRequest(msalInstance);
  }
};

// Application
const Application = ({history, pca}) => {
  TagManager.initialize({
    gtmId: config.GA_TAG_MANAGER_ID,
    dataLayerName: 'PageDataLayer',
  });

  const dispatch = useAppDispatch();

  const {
    releaseDataCollectionEnabled,
    releaseDocumentRepositoryEnabled,
    releaseEsgEnabled,
    releaseOwnershipControlEnabled,
    releaseAiNewsEnabled,
    releaseNearCastingEnabled,
    releaseAttachDocumentsToDataEnabled,
    releaseAskAiEnabled,
    releaseInFlightModeEnabled,
    releaseCompanyDataVisualisationEnabled,
    releaseFilePreviewEnabled,
    assureRedirect,
    releasePersonalisedVisualisationEnabled,
    ga120FundOverviewPage,
    ga254FundsListPage,
    ga396FundOperationalPage,
    releaseForecastEnabled,
    releaseInvestmentCommentary,
    releaseCalculatedDataEnabled,
    beac333EnableUsers,
  } = useFlags();

  useEffect(() => {
    dispatch(
      setFeatureFlags({
        releaseDataCollectionEnabled,
        releaseDocumentRepositoryEnabled,
        releaseEsgEnabled,
        releaseOwnershipControlEnabled,
        releaseAiNewsEnabled,
        releaseNearCastingEnabled,
        releaseAttachDocumentsToDataEnabled,
        releaseAskAiEnabled,
        releaseInFlightModeEnabled,
        releaseCompanyDataVisualisationEnabled,
        releaseFilePreviewEnabled,
        releasePersonalisedVisualisationEnabled,
        ga120FundOverviewPage,
        ga254FundsListPage,
        ga396FundOperationalPage,
        releaseForecastEnabled,
        releaseInvestmentCommentary,
        assureRedirect,
        releaseCalculatedDataEnabled,
        beac333EnableUsers,
      })
    );
  }, [
    dispatch,
    releaseAiNewsEnabled,
    releaseAttachDocumentsToDataEnabled,
    releaseDataCollectionEnabled,
    releaseDocumentRepositoryEnabled,
    releaseEsgEnabled,
    releaseNearCastingEnabled,
    releaseOwnershipControlEnabled,
    releaseAskAiEnabled,
    releaseInFlightModeEnabled,
    releaseCompanyDataVisualisationEnabled,
    releaseFilePreviewEnabled,
    releasePersonalisedVisualisationEnabled,
    ga120FundOverviewPage,
    ga254FundsListPage,
    ga396FundOperationalPage,
    releaseForecastEnabled,
    releaseInvestmentCommentary,
    assureRedirect,
    releaseCalculatedDataEnabled,
    beac333EnableUsers,
  ]);

  return (
    <ConnectedRouter history={history}>
      {renderRoutes(routes, {pca})}
    </ConnectedRouter>
  );
};

(async () => {
  let activeAccount;
  let sub;

  try {
    await msalInstance.handleRedirectPromise();
    activeAccount = msalInstance.getActiveAccount();
    if (!activeAccount) {
      return msalInstance.loginRedirect();
    }
    const request = {
      scopes: [config.API_SCOPES],
      account: activeAccount || accounts[0],
    };

    const tokenResult = await msalInstance.acquireTokenSilent(request);
    sub = tokenResult.idTokenClaims?.sub;
  } catch (e) {
    console.warn('Error: ' + e);
    await acquireTokenRedirectRequest(msalInstance);
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.LAUNCHDARKLY_CLIENT_ID,
    context: {
      kind: 'user',
      key: sub,
      email: activeAccount?.username,
    },
  });

  ReactDOM.render(
    <Provider store={store}>
      <LDProvider>
        <Application history={history} pca={msalInstance} />
      </LDProvider>
    </Provider>,
    document.getElementById('root')
  );
})();
