import {useMemo} from 'react';

function useBarWidths({
  chartWidth,
  barCount,
  gap = 10,
  maxBarWidth = 56,
  minBarWidthForLabels = 40,
}: {
  chartWidth: number;
  barCount: number;
  gap?: number;
  maxBarWidth?: number;
  minBarWidthForLabels?: number;
}) {
  const {barWidth, showLabels} = useMemo(() => {
    // Calculate the total width allocated to gaps
    const totalGapWidth = gap * (barCount - 1);

    // Calculate the raw available width for bars
    const availableWidth = chartWidth - totalGapWidth;

    // Calculate the raw width of each bar
    const rawBarWidth = availableWidth / barCount;

    // Ensure bar width does not exceed maxBarWidth
    let barWidth = Math.min(rawBarWidth, maxBarWidth);

    // Adjust total chart width based on calculated barWidth
    const adjustedChartWidth = barWidth * barCount + totalGapWidth;

    // If bars overlap, reduce bar width further
    if (adjustedChartWidth > chartWidth) {
      barWidth = (chartWidth - totalGapWidth) / barCount;
      barWidth = Math.min(barWidth, maxBarWidth);
    }

    // Round bar width down to 1 decimal place
    barWidth = Math.floor(barWidth * 10) / 10;

    // Define a threshold for label visibility based on minimum bar width
    const showLabels = barWidth >= minBarWidthForLabels;

    return {barWidth, showLabels};
  }, [chartWidth, barCount, gap, maxBarWidth, minBarWidthForLabels]);

  return {barWidth, showLabels};
}

export default useBarWidths;
