import {call, put, all, takeEvery, select} from 'redux-saga/effects';
import {RootState} from '../../store';
import {getAllFunds} from '../../api/getAllFunds';
import {acquireAuthResult, msalInstance} from '../../';
import {Funds} from '../../types/index';
import {errorHandlerAction} from '../../actions';
import {
  getFundPerformanceDataAction,
  setFundPerformanceData,
  setFunds,
  clearFunds,
} from '../../reducers/fundsSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  FundListPerformanceResponse,
  getFundPerformanceData,
} from '../../api/getFundPerformanceData';

export const getAllFundsSaga = function* getAllFundsSaga(): any {
  const ga254FundsListPage = yield select(
    (state: RootState) => state.featureFlags.data.ga254FundsListPage
  );

  if (ga254FundsListPage) {
    window.location.replace('/fund-monitoring/funds/');
  }

  const isUserProfileUpdatePending = yield select(
    (state: RootState) => state.userProfile.isLoading
  );

  if (!isUserProfileUpdatePending) {
    yield put(clearFunds());
  }

  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: Funds = yield call(getAllFunds, accessToken);
    yield put(setFunds(response));
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const getFundPerformanceDataSaga = function* getFundPerformanceDataSaga({
  payload,
}: PayloadAction<{fundId: string; fundType: 'activeFunds' | 'inactiveFunds'}>) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: FundListPerformanceResponse = yield call(
      getFundPerformanceData,
      accessToken,
      payload.fundId
    );
    yield put(
      setFundPerformanceData({
        performanceResponse: response,
        fundId: payload.fundId,
        fundType: payload.fundType,
      })
    );
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const getAllFundsSagaWatcher = function* getAllFundsSagaWatcher() {
  yield all([
    takeEvery(getFundPerformanceDataAction.type, getFundPerformanceDataSaga),
  ]);
};
