import {all, call, put, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../..';
import {DataCollectionDetails, ReportingTableTemplateType} from '../../types';
import {errorHandlerAction, pageErrorHandlerAction} from '../../actions';
import {
  clearDataCollectionDetailsData,
  getDataCollectionHistoricalReporting,
  setDataCollectionDetailsData,
  setDataCollectionHistoricalData,
  setIsHistoricalDataLoading,
} from '../../reducers/dataCollectionDetailsSlice';
import {
  getDataCollectionDetails,
  getDataCollectionHistoricalReportingData,
  HistoricalReportingDataResponse,
} from '../../api/getDataCollectionDetails';
import {
  clearReportingTableData,
  setCollapseReset,
  setHistoricalReportingTableData,
  setReportingTableData,
} from '../../reducers/reportingTableSlice';

export const dataCollectionDetailsSaga = function* dataCollectionDetailsSaga({
  payload,
}: any) {
  const id: string = payload?.match?.params?.id;
  if (id) {
    yield put(clearDataCollectionDetailsData());
    yield put(clearReportingTableData());
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: DataCollectionDetails = yield call(
        getDataCollectionDetails,
        accessToken,
        id
      );
      yield put(setDataCollectionDetailsData(response));
      yield put(
        setReportingTableData(response.data.map(data => data.reportingData))
      );
    } catch (err) {
      console.warn(err);
      yield put(pageErrorHandlerAction({error: err}));
    }
  }
};

export const getDataCollectionHistoricalReportingSaga =
  function* getDataCollectionHistoricalReportingSaga({payload}: any) {
    if (payload.id) {
      try {
        yield put(setIsHistoricalDataLoading(true));
        yield put(
          setCollapseReset({
            collapseReset: true,
            reportingTableTemplateType: payload.templateType,
          })
        );
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: HistoricalReportingDataResponse = yield call(
          getDataCollectionHistoricalReportingData,
          accessToken,
          payload.id
        );

        yield put(
          setDataCollectionHistoricalData({
            isHistorical: response.isHistorical,
            reportingTableDataType: response.reportingData
              .type as ReportingTableTemplateType,
          })
        );
        yield put(setHistoricalReportingTableData(response.reportingData));
        yield put(setIsHistoricalDataLoading(false));
      } catch (err) {
        console.warn(err);
        yield put(setIsHistoricalDataLoading(false));
        yield put(errorHandlerAction({error: err}));
      }
    }
  };

export const dataCollectionDetailsSagaWatcher =
  function* dataCollectionDetailsSagaWatcher() {
    yield all([
      takeEvery(
        getDataCollectionHistoricalReporting.type,
        getDataCollectionHistoricalReportingSaga
      ),
    ]);
  };
