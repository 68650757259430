import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {RootState} from '../../store';
import {acquireAuthResult, msalInstance} from '../../index';
import {FeeAnalysisData, FundOperational, InsightsData} from '../../types';
import {errorHandlerAction, pageErrorHandlerAction} from '../../actions';
import {
  clearFundOperationalData,
  setFeeAnalysisUpdateInProgress,
  setFundOperationalData,
  updateFeeAnalysisData,
  updateFeeAnalysisDataAction,
  updateInsightsData,
  updateInsightsDataAction,
} from '../../reducers/fundDetailsOperationalSlice';
import {
  getFeeAnalysisData,
  getFundDetailsOperational,
  getFundInsightsData,
} from '../../api/getFundDetailsOperational';
import {PayloadAction} from '@reduxjs/toolkit';

export const fundDetailsOperationalSaga = function* fundDetailsOperationalSaga({
  payload,
}: any): any {
  const id: string = payload?.match?.params?.id;
  if (id) {
    const ga396FundOperationalPage = yield select(
      (state: RootState) => state.featureFlags.data.ga396FundOperationalPage
    );

    if (ga396FundOperationalPage) {
      window.location.replace(`/fund-monitoring/funds/${id}/operational`);
    }

    const isUserProfileUpdatePending = yield select(
      (state: RootState) => state.userProfile.isLoading
    );
    if (!isUserProfileUpdatePending) {
      yield put(clearFundOperationalData());
    }
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: FundOperational = yield call(
        getFundDetailsOperational,
        accessToken,
        id
      );
      yield put(setFundOperationalData(response));
    } catch (err) {
      console.warn(err);
      yield put(pageErrorHandlerAction({error: err}));
    }
  }
};

export const updateFeeAnalysisDataSaga = function* updateFeeAnalysisDataSaga({
  payload,
}: PayloadAction<{
  fundId: string;
  from: string;
  to: string;
  entityId: string;
  includeManagementFees: boolean;
}>) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const {fundId, from, to, entityId, includeManagementFees} = payload;
    yield put(setFeeAnalysisUpdateInProgress(true));
    yield delay(500);
    const response: FeeAnalysisData = yield call(
      getFeeAnalysisData,
      accessToken,
      fundId,
      from,
      to,
      includeManagementFees,
      entityId
    );
    yield put(updateFeeAnalysisData(response));
  } catch (err) {
    yield put(setFeeAnalysisUpdateInProgress(false));
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const updateInsightsDataSaga = function* updateInsightsDataSaga({
  payload,
}: PayloadAction<{fundId: string; date: string}>) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const {fundId, date} = payload;
    const response: InsightsData = yield call(
      getFundInsightsData,
      accessToken,
      fundId,
      date
    );
    yield put(updateInsightsData(response));
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const fundDetailsOperationalSagaWatcher =
  function* fundDetailsOperationalSagaWatcher() {
    yield all([
      takeLatest(updateFeeAnalysisDataAction.type, updateFeeAnalysisDataSaga),
      takeEvery(updateInsightsDataAction.type, updateInsightsDataSaga),
    ]);
  };
