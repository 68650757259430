import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {useMsal} from '@azure/msal-react';
import {useIdleTimer} from 'react-idle-timer';
import {Button, Icon, Modal} from '../index';
import {useTranslation} from 'react-i18next';
import {ButtonStyle} from '../../../types';
import config from '../../../config';
import styles from './UserSessionTimeOutHandler.module.scss';
import {removeCookie} from '../../../utils';

const PROMPT_BEFORE_IDLE = 60000;

interface UserSessionTimeOutHandlerProps {
  children: React.ReactNode;
}
const UserSessionTimeOutHandler: React.FC<UserSessionTimeOutHandlerProps> = ({
  children,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [remaining, setRemaining] = useState<number>(
    Math.ceil(PROMPT_BEFORE_IDLE / 1000)
  );
  const {instance} = useMsal();
  const {t} = useTranslation();

  const onIdle = () => {
    removeCookie('LANTERN_ACCESS_TOKEN');
    instance.logoutRedirect();
  };

  const onActive = () => {
    setShowModal(false);
    setRemaining(Math.ceil(PROMPT_BEFORE_IDLE / 1000));
  };

  const handleClickResume = () => {
    activate();
  };

  const onPrompt = () => {
    setShowModal(true);
  };

  const {activate, getRemainingTime} = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    timeout: Number(config.SESSION_TIMEOUT_MS || 900000),
    throttle: 500,
    crossTab: true,
  });

  useEffect(() => {
    const interval = showModal
      ? setInterval(() => {
          setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 1000)
      : undefined;

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div
      className={classnames(
        styles.wrapper,
        !!showModal ? styles.wrapper__locked : ''
      )}
    >
      {showModal ? (
        <Modal
          isOpen={showModal}
          isPortal={false}
          onClose={handleClickResume}
          closeOnOutsideClick={false}
          zIndex={12}
          footerChildren={
            <div className={styles.actionButtons}>
              <Button
                text={t('UserSessionTimeOutHandler.ButtonLabel.Logout')}
                styleType={ButtonStyle.Primary}
                onClick={() => {
                  removeCookie('LANTERN_ACCESS_TOKEN');
                  instance.logoutRedirect();
                }}
                className={styles.lightThemeSecondaryButton}
              />
              <Button
                text={t(`UserSessionTimeOutHandler.ButtonLabel.Continue`, {
                  remaining,
                })}
                styleType={ButtonStyle.Secondary}
                onClick={handleClickResume}
                className={styles.lightThemePrimaryButton}
              />
            </div>
          }
        >
          <div className={styles.content}>
            <Icon className={styles.icon} name={'warning'} />
            <div className={styles.message}>
              {t('UserSessionTimeOutHandler.Message.Warning')}
            </div>
          </div>
        </Modal>
      ) : null}
      {children}
    </div>
  );
};

export default UserSessionTimeOutHandler;
