import React, {useEffect} from 'react';
import {SectionHeader} from '../../dashboard';
import styles from './ReportingPageReportingTableDashboard.module.scss';
import {
  FormStatus,
  ReportingTableData,
  ReportingTableStructureType,
  ReportingTableTemplateType,
} from '../../../types';
import ReportingTable from '../../global/table/reporting-table/ReportingTable';
import {useAppDispatch} from '../../../hooks/useReduxHooks';
import {
  cancelUpdateColumnAction,
  exportReportingDataAction,
  setActiveCanceledColumnId,
  setActiveReportingTableRowsData,
  setActiveSubmitColumnId,
  setActiveUpdateColumnId,
  setDataCellErrorMessage,
  setForecastIsOnFinalPage,
  submitColumnData,
  // submitColumnsData,
  submitDataCellAction,
  submitMultipleCellsData,
  unlockColumnAction,
  updateColumnDataAction,
  validateColumnCellData,
  validateColumnsAndShowNext,
  // validateColumnsAndSubmit,
} from '../../../reducers/reportingTableSlice';
import ReportingTableColumnSubmissionConfirmationDialog from '../../global/table/reporting-table/components/ReportingTableColumnSubmissionConfirmationDialog';
import {
  extractUploadDocumentsParamsFromUrl,
  getReportingPeriodForConfirmation,
  // getVisibleColumnsIds,
} from '../../../utils';
import Questionnaire from '../../global/questionnaire/Questionnaire';
import {useTranslation} from 'react-i18next';
import {IconButton} from '../../global';
import {
  getUploadDocumentsDataAction,
  setIsUploadDocumentsModalOpen,
} from '../../../reducers/uploadDocumentsSlice';

type ReportingPageReportingTableDashboardProps = {
  data: ReportingTableData;
  isHistorical: boolean;
  activeSubmitColumnId: string | null;
  columnSubmittingStatus: FormStatus;
  columnUnlockingStatus?: FormStatus;
  activeUpdateColumnId: string | null;
  activeCanceledColumnId: string | null;
  activeUnlockedColumnId: string | null;
  exportPending: boolean;
};

const ReportingPageReportingTableDashboard: React.FC<
  ReportingPageReportingTableDashboardProps
> = ({
  data,
  isHistorical,
  activeSubmitColumnId,
  columnSubmittingStatus,
  columnUnlockingStatus,
  activeUpdateColumnId,
  activeCanceledColumnId,
  activeUnlockedColumnId,
  exportPending,
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const exportReportingTable = () => {
    dispatch(exportReportingDataAction(data.id));
  };

  const messageAction = () => {
    if (activeUpdateColumnId) return 'update';
    if (activeCanceledColumnId) return 'cancel';
    return 'submit';
  };

  const activeReportingTableRowsHandler = (
    collapsed: boolean,
    activeOffset: number
  ) => {
    dispatch(
      setActiveReportingTableRowsData({
        reportingData: data,
        collapsed: collapsed,
        activeOffset,
      })
    );
  };

  const onSubmitColumn = () => {
    if (activeSubmitColumnId) {
      if (
        data.type === ReportingTableTemplateType.ActualsReportingTemplate ||
        data.type === ReportingTableTemplateType.ESGReportingTemplate ||
        data.type === ReportingTableTemplateType.ForecastReportingTemplate
      ) {
        dispatch(submitColumnData(activeSubmitColumnId, data.type));
      }
      // else if (
      //   data.type === ReportingTableTemplateType.ForecastReportingTemplate
      // ) {
      //   dispatch(submitColumnsData(getVisibleColumnsIds(data.dataRows, false)));
      // }
    }
    if (activeCanceledColumnId) {
      if (
        data.type === ReportingTableTemplateType.ActualsReportingTemplate ||
        data.type === ReportingTableTemplateType.ForecastReportingTemplate
      ) {
        dispatch(cancelUpdateColumnAction(activeCanceledColumnId, data.type));
      }
    }
    if (activeUpdateColumnId) {
      if (
        data.type === ReportingTableTemplateType.ActualsReportingTemplate ||
        data.type === ReportingTableTemplateType.ForecastReportingTemplate
      ) {
        dispatch(updateColumnDataAction(activeUpdateColumnId, data.type));
      }
    }
  };

  useEffect(() => {
    if (columnSubmittingStatus === FormStatus.Ready) {
      dispatch(setActiveSubmitColumnId(null));
      dispatch(setActiveCanceledColumnId(null));
      dispatch(setActiveUpdateColumnId(null));
    }
  }, [columnSubmittingStatus, dispatch]);

  return (
    <div className={styles.wrapper}>
      <SectionHeader
        className={styles.sectionHeader}
        onClick={() => {}}
        withActionButton={false}
        childrenRight={[
          <IconButton
            loading={exportPending}
            icon={'export'}
            onClick={exportReportingTable}
          />,
        ]}
      />

      {data.structureType === ReportingTableStructureType.Table && (
        <ReportingTable
          reportingData={data}
          activeRowsData={{
            statusRows: data.statusRows,
            dataRows: data.dataRows,
          }}
          readOnly={isHistorical}
          isHistorical={isHistorical}
          headerLength={data.headerLength}
          rowLength={data.rowLength}
          onCollapsed={activeReportingTableRowsHandler}
          columnUnlockingStatus={columnUnlockingStatus}
          activeUnlockedColumnId={activeUnlockedColumnId}
          onPrevious={(collapsed, activeOffset) => {
            activeReportingTableRowsHandler(collapsed, activeOffset);
            dispatch(
              setForecastIsOnFinalPage({
                isOnFinalPage: false,
                reportingTableTemplateType:
                  ReportingTableTemplateType.ForecastReportingTemplate,
              })
            );
          }}
          onNext={(collapsed, activeOffset) => {
            if (
              data.type === ReportingTableTemplateType.ActualsReportingTemplate
            ) {
              activeReportingTableRowsHandler(collapsed, activeOffset);
            } else if (
              data.type === ReportingTableTemplateType.ForecastReportingTemplate
            ) {
              dispatch(
                validateColumnsAndShowNext(data, collapsed, activeOffset, true)
              );
            }
          }}
          onColumnSubmit={(columnId, submitted) => {
            if (
              data.type ===
                ReportingTableTemplateType.ActualsReportingTemplate ||
              data.type === ReportingTableTemplateType.ForecastReportingTemplate
            ) {
              dispatch(
                validateColumnCellData({
                  columnId,
                  reportingTableTemplateType:
                    data.type as ReportingTableTemplateType,
                  submitted,
                })
              );
            }
            // else if (
            //   data.type === ReportingTableTemplateType.ForecastReportingTemplate
            // ) {
            //   dispatch(validateColumnsAndSubmit(data));
            // }
          }}
          onColumnUnlock={columnId => {
            if (
              data.type ===
                ReportingTableTemplateType.ActualsReportingTemplate ||
              data.type === ReportingTableTemplateType.ForecastReportingTemplate
            ) {
              return dispatch(unlockColumnAction(columnId, data.type));
            }
          }}
          onCellSubmit={cell => {
            dispatch(submitDataCellAction(cell));
          }}
          onColumnCancel={columnId => {
            dispatch(setActiveCanceledColumnId(columnId));
          }}
          onSetDataCellErrorMessage={payload => {
            dispatch(setDataCellErrorMessage(payload));
          }}
          onPaste={(clipboardData, cellId) => {
            dispatch(
              submitMultipleCellsData(
                data.dataRows,
                clipboardData,
                cellId,
                data.type as ReportingTableTemplateType
              )
            );
          }}
          onGetReportingPeriodDocuments={columnId => {
            const uploadDocumentParams =
              extractUploadDocumentsParamsFromUrl(null);
            dispatch(setIsUploadDocumentsModalOpen(true));
            dispatch(
              getUploadDocumentsDataAction({
                ...uploadDocumentParams,
                transactions: null,
                columnIds: [columnId],
              })
            );
          }}
        />
      )}

      {data.structureType === ReportingTableStructureType.Questionnaire && (
        <Questionnaire
          isHistorical={isHistorical}
          reportingData={data}
          onInputSubmit={cell => {
            dispatch(submitDataCellAction(cell));
          }}
          onQuestionnaireSubmit={columnId => {
            dispatch(setActiveSubmitColumnId(columnId));
          }}
        />
      )}

      <ReportingTableColumnSubmissionConfirmationDialog
        actionType={
          !!activeCanceledColumnId
            ? 'cancel'
            : !!activeUpdateColumnId
            ? 'update'
            : 'submit'
        }
        message={getReportingPeriodForConfirmation(
          data,
          activeSubmitColumnId ||
            activeCanceledColumnId ||
            activeUpdateColumnId,
          t,
          messageAction()
        )}
        isOpen={
          !!activeSubmitColumnId ||
          !!activeCanceledColumnId ||
          !!activeUpdateColumnId
        }
        onClose={() => {
          dispatch(setActiveSubmitColumnId(null));
          dispatch(setActiveUpdateColumnId(null));
          dispatch(setActiveCanceledColumnId(null));
        }}
        onCancel={() => {
          dispatch(setActiveSubmitColumnId(null));
          dispatch(setActiveUpdateColumnId(null));
          dispatch(setActiveCanceledColumnId(null));
        }}
        onSubmit={onSubmitColumn}
        isLoading={columnSubmittingStatus === FormStatus.Pending}
      />
    </div>
  );
};

export default ReportingPageReportingTableDashboard;
