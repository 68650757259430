import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useAppSelector} from '../../../../hooks/useReduxHooks';
import styles from './ReportingTable.module.scss';
import ReportingTableDataCell from './components/ReportingTableDataCell';
import {IconButton} from '../..';
import classnames from 'classnames';
import ReportingTableSeparatorCell from './components/ReportingTableSeparatorCell';
import ReportingTableEmptyCell from './components/ReportingTableEmptyCell';
import ReportingTableStatusPeriodDateCell from './components/ReportingTableStatusPeriodDateCell';
import ReportingTableStatusSubmissionDateCell from './components/ReportingTableStatusSubmissionDateCell';
import ReportingTableStatusCell from './components/ReportingTableStatusCell';
import ReportingTablePaddingCell from './components/ReportingTablePaddingCell';
import ReportingTableHeaderCell from './components/ReportingTableHeaderCell';
import ReportingTableGroupHeaderCell from './components/ReportingTableGroupHeaderCell';
import ReportingTablePaginationCell from './components/ReportingTablePaginationCell';
import ReportingTableTemplateHeaderCell from './components/ReportingTableTemplateHeaderCell';
import ReportingTableGroupSubHeaderCell from './components/ReportingTableGroupSubHeaderCell';
import ReportingTableMandatoryIndicatorCell from './components/ReportingTableMandatoryIndicatorCell';
import ReportingTableForecastStatusCell from './components/ReportingTableForecastStatusCell';
import ReportingTableHistoricalReportDateCell from './components/ReportingTableHistoricalReportDateCell';
import ReportingTableTextDataCell from './components/ReportingTableTextDataCell';
import {
  ButtonStyle,
  ForecastReportingInterval,
  ReportingTableCellTypes,
  ReportingTableProps,
  ReportingTableRow,
  ReportingTableRowTypes,
  ReportingTableStructureType,
  ReportingTableTemplateType,
} from '../../../../types';
import {
  emptyCellData,
  firstCharToLowercase,
  setupReportingTableKeyboardEvent,
  setupReportingTableMouseCellSelectionEvents,
} from '../../../../utils';
import {questionnaireInputTypes} from '../../../../constants/reporting';
import ReportingTableStatusUpdateDateCellProps from './components/ReportingTableStatusUpdateDateCell';
import ReportingTableStatusDocumentsCell from './components/ReportingTableStatusDocumentsCell';

const ReportingTable = ({
  reportingData,
  activeRowsData,
  headerLength,
  rowLength,
  readOnly,
  isHistorical,
  onCollapsed,
  onPrevious,
  onNext,
  onColumnSubmit,
  onColumnUnlock,
  onCellSubmit,
  onColumnCancel,
  onSetDataCellErrorMessage,
  onGetReportingPeriodDocuments,
  onCopy,
  onPaste,
  columnUnlockingStatus,
  activeUnlockedColumnId,
}: ReportingTableProps) => {
  const {releaseAttachDocumentsToDataEnabled} = useAppSelector(
    state => state.featureFlags.data
  );

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [activeOffset, setActiveOffset] = useState(rowLength);
  const [previousDisabled, setPreviousDisabled] = useState(
    collapsed ? rowLength <= 6 : rowLength <= 3
  );
  const [nextDisabled, setNextDisabled] = useState(true);
  const statusHeaderRef = useRef(null);
  const tableSelectionRef = useRef<string>('');

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) =>
        entry.target.classList.toggle(
          styles.statusHeaderStuck,
          entry.intersectionRatio < 1
        ),
      {threshold: [1]}
    );

    if (statusHeaderRef.current) {
      observer.observe(statusHeaderRef.current);
    }

    // Select reporting table
    const reportingTableEl = document.querySelector(
      `.${styles.templateReportingTable}`
    ) as HTMLElement;

    // Setup mouse selection events
    let removeReportingTableMouseCellSelectionEvents: any;
    if (reportingTableEl)
      removeReportingTableMouseCellSelectionEvents =
        setupReportingTableMouseCellSelectionEvents(
          reportingTableEl,
          styles.cellSelected,
          styles.selectionOverlay,
          tableSelectionRef
        );

    return () => {
      if (statusHeaderRef.current) {
        observer.unobserve(statusHeaderRef.current);
      }

      if (removeReportingTableMouseCellSelectionEvents)
        removeReportingTableMouseCellSelectionEvents();
    };
  }, []);

  useEffect(() => {
    setActiveOffset(rowLength);
  }, [reportingData.type, rowLength]);

  useEffect(() => {
    // Setup ReportingTable keyboard navigation event listeners
    const removeReportingTableKeyboardEvent = setupReportingTableKeyboardEvent(
      `.${styles.templateReportingTable}`,
      `.${styles.dataCell__editable} input`,
      'reportingTableMandatoryErrorMessage',
      collapsed,
      activeRowsData.dataRows
    );

    return () => {
      // Remove ReportingTable keyboard navigation event listeners
      removeReportingTableKeyboardEvent();
    };
  }, [activeRowsData, activeOffset, collapsed]);

  useEffect(() => {
    if (!!reportingData?.collapseReset && !collapsed) onCollapseHandler();
  }, [reportingData?.collapseReset]);

  const headerColumnsCount = useMemo(() => {
    if (
      reportingData.type === ReportingTableTemplateType.ESGReportingTemplate
    ) {
      return collapsed ? 3 : 5;
    }

    if (
      reportingData.type ===
        ReportingTableTemplateType.ActualsReportingTemplate ||
      reportingData.type ===
        ReportingTableTemplateType.ForecastReportingTemplate
    ) {
      return collapsed ? 2 : 4;
    }

    return collapsed ? 2 : 4;
  }, [reportingData, collapsed]);

  const dataColumnsCount = useMemo(() => {
    if (
      reportingData.type === ReportingTableTemplateType.ActualsReportingTemplate
    ) {
      return 6;
    }

    if (
      reportingData.type === ReportingTableTemplateType.ESGReportingTemplate
    ) {
      return 3;
    }

    // if (
    //   reportingData.type ===
    //   ReportingTableTemplateType.ForecastReportingTemplate
    // ) {
    //   const forecastStatusCellValue = reportingData.statusRows
    //     .find(row => row.type === ReportingTableRowTypes.StatusHeaderRow)
    //     ?.cells.find(
    //       cell =>
    //         cell.type === ReportingTableCellTypes.ForecastStatusCell &&
    //         !!cell?.data?.value &&
    //         cell?.data?.value?.isActivePeriod
    //     )?.data?.value;

    //   if (
    //     forecastStatusCellValue?.interval ===
    //     ForecastReportingInterval.QuarterlyForecast
    //   ) {
    //     return 4;
    //   }
    // }

    return 6;
  }, [reportingData]);

  const headerGroupCols = useMemo<ReactElement>(() => {
    if (
      reportingData.type === ReportingTableTemplateType.ESGReportingTemplate
    ) {
      return (
        <>
          <col style={{width: 'clamp(150px, 1rem + 10vw, 300px)'}} />
          <col style={{width: 'clamp(250px, 1rem + 10vw, 500px)'}} />{' '}
          {/* Adjusted 100px to 500px for logical consistency */}
          <col style={{width: 'clamp(50px, 1rem + 10vw, 100px)'}} />
          <col />
        </>
      );
    }

    if (
      reportingData.type ===
        ReportingTableTemplateType.ActualsReportingTemplate ||
      reportingData.type ===
        ReportingTableTemplateType.ForecastReportingTemplate
    ) {
      return (
        <>
          <col style={{width: 'clamp(150px, 1rem + 10vw, 300px)'}} />
          <col style={{width: 'clamp(50px, 1rem + 10vw, 100px)'}} />
          {!collapsed && (
            <>
              <col style={{width: 'clamp(50px, 1rem + 10vw, 100px)'}} />
              <col style={{width: 'clamp(50px, 1rem + 10vw, 100px)'}} />
            </>
          )}
          <col />
        </>
      );
    }

    return <></>;
  }, [reportingData, collapsed]);

  const dataGroupCols = useMemo<ReactElement>(() => {
    // if (
    //   reportingData.type ===
    //   ReportingTableTemplateType.ForecastReportingTemplate
    // ) {
    //   return (
    //     <>
    //       {collapsed && <col />}
    //       <col />
    //       <col />
    //       <col />
    //     </>
    //   );
    // }

    if (
      reportingData.type === ReportingTableTemplateType.ESGReportingTemplate
    ) {
      return (
        <>
          <col />
          <col />
          <col />
        </>
      );
    }

    if (
      reportingData.type ===
        ReportingTableTemplateType.ActualsReportingTemplate ||
      ReportingTableTemplateType.ForecastReportingTemplate
    ) {
      return (
        <>
          {collapsed && (
            <>
              <col />
              <col />
              <col />
            </>
          )}
          <col />
          <col />
          <col />
        </>
      );
    }

    return <></>;
  }, [reportingData, collapsed]);

  const onCollapseHandler = () => {
    const setPaginationButtonsState = (
      isCollapsed: boolean,
      activeOffset: number
    ) => {
      if (
        (isCollapsed && activeOffset - 6 === 0) ||
        (!collapsed && rowLength > 6 && activeOffset - 1 === 0) ||
        (!collapsed && rowLength < 6 && activeOffset - 3 === 0)
      ) {
        setPreviousDisabled(true);
        setNextDisabled(false);
      } else if (activeOffset + 1 > rowLength) {
        setNextDisabled(true);
        setPreviousDisabled(false);
      } else {
        setPreviousDisabled(false);
        setNextDisabled(false);
      }

      if (rowLength <= 3) {
        setPreviousDisabled(true);
        setNextDisabled(true);
      }
    };
    const isCollapsed = !collapsed;

    if (
      (reportingData.type ===
        ReportingTableTemplateType.ActualsReportingTemplate ||
        reportingData.type ===
          ReportingTableTemplateType.ForecastReportingTemplate) &&
      isCollapsed &&
      activeOffset < 3
    ) {
      onCollapsed(isCollapsed, 6);
      setActiveOffset(3);

      if (rowLength < 3) {
        setPreviousDisabled(true);
        setNextDisabled(true);
      }

      setCollapsed(isCollapsed);
      return;
    }

    if (
      (reportingData.type ===
        ReportingTableTemplateType.ActualsReportingTemplate ||
        reportingData.type ===
          ReportingTableTemplateType.ForecastReportingTemplate) &&
      isCollapsed &&
      activeOffset <= 6
    ) {
      // In the case where we have more that 3 and less or equal than 6 columns
      // available we'll need to compute activeOffset and return the rowLength as
      // activeOffset to avoid issues with calculating pagination when (un)collapsing
      // the table columns
      const computedActiveOffset =
        rowLength <= 6 && rowLength > 3 ? rowLength : 6;
      onCollapsed(isCollapsed, computedActiveOffset);
      setActiveOffset(computedActiveOffset);

      if (rowLength <= 6) {
        setPreviousDisabled(true);
        setNextDisabled(true);
      }

      if (rowLength > 6) {
        setPreviousDisabled(true);
        setNextDisabled(false);
      }

      setCollapsed(isCollapsed);
      return;
    }

    onCollapsed(isCollapsed, activeOffset);
    setPaginationButtonsState(isCollapsed, activeOffset);

    setCollapsed(isCollapsed);
  };

  const onPreviousHandler = () => {
    if (
      ((reportingData.type ===
        ReportingTableTemplateType.ActualsReportingTemplate ||
        reportingData.type ===
          ReportingTableTemplateType.ForecastReportingTemplate) &&
        ((collapsed && activeOffset - 6 === 0) ||
          (!collapsed && rowLength > 6 && activeOffset - 1 === 0) ||
          (!collapsed && rowLength < 6 && activeOffset - 3 === 0))) ||
      (reportingData.type === ReportingTableTemplateType.ESGReportingTemplate &&
        ((collapsed && activeOffset - 3 === 0) ||
          (!collapsed && rowLength > 3 && activeOffset - 1 === 0) ||
          (!collapsed && rowLength < 3 && activeOffset - 3 === 0)))
    )
      return;

    const newActiveOffset = activeOffset - 1;
    onPrevious(collapsed, newActiveOffset);
    if (
      ((reportingData.type ===
        ReportingTableTemplateType.ActualsReportingTemplate ||
        reportingData.type ===
          ReportingTableTemplateType.ForecastReportingTemplate) &&
        ((collapsed && newActiveOffset - 6 === 0) ||
          (!collapsed && rowLength === 6 && newActiveOffset - 3 === 0) ||
          (!collapsed && rowLength > 6 && newActiveOffset - 3 === 0) ||
          (!collapsed && rowLength < 6 && newActiveOffset - 3 === 0))) ||
      (reportingData.type === ReportingTableTemplateType.ESGReportingTemplate &&
        ((collapsed && newActiveOffset - 3 === 0) ||
          (!collapsed && rowLength === 3 && newActiveOffset - 3 === 0) ||
          (!collapsed && rowLength > 3 && newActiveOffset - 3 === 0) ||
          (!collapsed && rowLength < 3 && newActiveOffset - 3 === 0)))
    ) {
      setPreviousDisabled(true);
      setNextDisabled(false);
    } else {
      setNextDisabled(false);
    }
    setActiveOffset(newActiveOffset);
  };

  const onNextHandler = () => {
    const newActiveOffset = activeOffset + 1;
    onNext(collapsed, newActiveOffset);
    if (newActiveOffset + 1 > rowLength) {
      setNextDisabled(true);
      setPreviousDisabled(false);
    } else setPreviousDisabled(false);
    setActiveOffset(newActiveOffset);
  };

  const renderRow = (row: ReportingTableRow, rowIndex: number) => {
    // Don't render StatusDocumentsRow until IS_LP_3066_ATTACH_DOCUMENTS_TO_DATA_ENABLED featureFlag is enabled
    // and real data is returned from the api
    if (
      row.type === ReportingTableRowTypes.StatusDocumentsRow &&
      !releaseAttachDocumentsToDataEnabled
    )
      return null;

    return (
      <tr
        key={`${row.type}-${rowIndex}-${'End'}`}
        className={classnames(styles[firstCharToLowercase(row.type)])}
      >
        <ReportingTablePaddingCell
          key={`${'PaddingCell'}-${row.type}-${rowIndex}`}
          classNames={classnames(styles.paddingCell)}
        />
        {row.cells.map((cell: any, cellIndex: number) => {
          if (cell.type === ReportingTableCellTypes.StatusUpdateDateCell) {
            return (
              <ReportingTableStatusUpdateDateCellProps
                cell={cell}
                key={cellIndex}
              />
            );
          }
          if (cell.type === ReportingTableCellTypes.TemplateHeaderCell) {
            return (
              <ReportingTableTemplateHeaderCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                cell={cell}
                classNames={classnames(styles[firstCharToLowercase(cell.type)])}
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.StatusCell) {
            return (
              <ReportingTableStatusCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                readonly={readOnly}
                isHistorical={!!isHistorical}
                cell={cell}
                onColumnSubmit={onColumnSubmit}
                onColumnUnlock={onColumnUnlock}
                onColumnCancel={onColumnCancel}
                columnUnlockingStatus={columnUnlockingStatus}
                activeUnlockedColumnId={activeUnlockedColumnId}
                justifyContent={
                  reportingData.structureType ===
                  ReportingTableStructureType.Questionnaire
                    ? 'flex-start'
                    : 'flex-end'
                }
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.ForecastStatusCell) {
            return (
              <ReportingTableForecastStatusCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                readonly={readOnly}
                cell={cell}
                colSpan={collapsed ? dataColumnsCount : 3}
                classNames={classnames(styles[firstCharToLowercase(cell.type)])}
                onColumnSubmit={onColumnSubmit}
                onNext={() => {
                  setActiveOffset((prev: number): number => {
                    if (prev + 6 === 7 || prev === 7) {
                      // !cell?.data?.value?.isOnFinalPage) {
                      const newActiveOffset = prev === 7 ? 7 : prev + 6;
                      onNext(collapsed, newActiveOffset);
                      return newActiveOffset;
                    }

                    return 1;
                  });
                }}
                onPrevious={() => {
                  setActiveOffset((prev: number): number => {
                    if (prev - 6 === 1) {
                      const newActiveOffset = prev - 6;
                      onPrevious(collapsed, newActiveOffset, setActiveOffset);

                      return newActiveOffset;
                    }

                    return prev;
                  });
                }}
                dataColumnCount={
                  cell?.data?.value?.interval ===
                  ForecastReportingInterval.TwelveMonthForecast
                    ? 12
                    : cell?.data?.value?.interval ===
                      ForecastReportingInterval.SixMonthForecast
                    ? 6
                    : cell?.data?.value?.interval ===
                      ForecastReportingInterval.QuarterlyForecast
                    ? 4
                    : 6
                }
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.StatusDocumentsCell) {
            return (
              <ReportingTableStatusDocumentsCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}-${cell.id}`}
                cell={cell}
                textAlign={
                  reportingData.structureType ===
                  ReportingTableStructureType.Questionnaire
                    ? 'left'
                    : 'right'
                }
                classNames={classnames(styles[firstCharToLowercase(cell.type)])}
                onGetReportingPeriodDocuments={onGetReportingPeriodDocuments}
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.StatusSubmissionDateCell) {
            return (
              <ReportingTableStatusSubmissionDateCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}-${cell.id}`}
                cell={cell}
                readOnly={readOnly}
                textAlign={
                  reportingData.structureType ===
                  ReportingTableStructureType.Questionnaire
                    ? 'left'
                    : 'right'
                }
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.StatusPeriodDateCell) {
            return (
              <ReportingTableStatusPeriodDateCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                cell={cell}
                classNames={classnames(styles[firstCharToLowercase(cell.type)])}
                textAlign={
                  reportingData.structureType ===
                  ReportingTableStructureType.Questionnaire
                    ? 'left'
                    : 'right'
                }
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.HistoricalReportDateCell) {
            return (
              <ReportingTableHistoricalReportDateCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                cell={cell}
                classNames={classnames(styles[firstCharToLowercase(cell.type)])}
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.EmptyCell) {
            return (
              <ReportingTableEmptyCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                cell={cell}
                classNames={classnames(styles[firstCharToLowercase(cell.type)])}
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.SeparatorCell) {
            return (
              <ReportingTableSeparatorCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.GroupHeaderCell) {
            return (
              <ReportingTableGroupHeaderCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                cell={cell}
                classNames={classnames(styles[firstCharToLowercase(cell.type)])}
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.GroupSubHeaderCell) {
            return (
              <ReportingTableGroupSubHeaderCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                cell={cell}
                classNames={classnames(styles[firstCharToLowercase(cell.type)])}
                textAlign={
                  (reportingData.structureType ===
                    ReportingTableStructureType.Questionnaire &&
                    ![2, 3, 4].includes(cellIndex)) ||
                  cellIndex === 0
                    ? 'left'
                    : 'center'
                }
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.HeaderCell) {
            return (
              <ReportingTableHeaderCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                cell={cell}
                verticalAlign={
                  reportingData.structureType ===
                  ReportingTableStructureType.Questionnaire
                    ? 'top'
                    : 'middle'
                }
                textAlign={
                  (reportingData.structureType ===
                    ReportingTableStructureType.Questionnaire &&
                    ![3, 4].includes(cellIndex)) ||
                  cellIndex === 0
                    ? 'left'
                    : 'center'
                }
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.MandatoryIndicatorCell) {
            return (
              <ReportingTableMandatoryIndicatorCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}`}
                cell={cell}
                verticalAlign={
                  reportingData.structureType ===
                  ReportingTableStructureType.Questionnaire
                    ? 'top'
                    : 'middle'
                }
                classNames={classnames(styles[firstCharToLowercase(cell.type)])}
              />
            );
          }

          if (cell.type === ReportingTableCellTypes.DataCell) {
            return (
              <ReportingTableDataCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}-${cell.id}`}
                cell={cell}
                readOnly={readOnly || cell.data?.isLocked || !cell.data}
                classNames={classnames(
                  styles[firstCharToLowercase(cell.type)],
                  {
                    [styles[`${firstCharToLowercase(cell.type)}__readOnly`]]:
                      readOnly || cell.data?.submitted || !cell.data,
                    [styles[`${firstCharToLowercase(cell.type)}__editable`]]:
                      !readOnly &&
                      (!cell.data?.submitted ||
                        (cell.data?.submitted && !cell.data?.isLocked)) &&
                      cell.isVisible &&
                      !!cell?.data,
                  }
                )}
                onCellSubmit={onCellSubmit}
                onSetDataCellErrorMessage={onSetDataCellErrorMessage}
                onCopy={onCopy}
                onPaste={(clipboardData, cellId) => {
                  if (!!onPaste)
                    onPaste(
                      clipboardData,
                      cellId,
                      `.${styles.dataCell__editable} input`
                    );
                }}
              />
            );
          }

          if (
            cell.type === ReportingTableCellTypes.TextDataCell ||
            questionnaireInputTypes.includes(cell.type)
          ) {
            return (
              <ReportingTableTextDataCell
                key={`${cell.type}-${row.type}-${rowIndex}-${cellIndex}-${cell.id}`}
                cell={cell}
                questionnaireOptions={row.questionnaire}
                // classNames={classnames(
                //   styles[firstCharToLowercase(cell.type)],
                //   {
                //     [styles[`${firstCharToLowercase(cell.type)}__readOnly`]]:
                //       readOnly || cell.data?.submitted || !cell.data,
                //     [styles[`${firstCharToLowercase(cell.type)}__editable`]]:
                //       !readOnly &&
                //       !cell.data?.submitted &&
                //       cell.isVisible &&
                //       !!cell?.data,
                //   }
                // )}
              />
            );
          }
        })}
        <ReportingTablePaddingCell
          key={`${'PaddingCell'}-${row.type}-${rowIndex}-${'End'}`}
          classNames={classnames(styles.paddingCell)}
        />
      </tr>
    );
  };

  const renderColGroup = () => {
    return (
      <colgroup>
        <>
          <col />
          {headerGroupCols}
          {dataGroupCols}
          <col />
        </>
      </colgroup>
    );
  };

  const renderNavigationRow = () => (
    <tr className={styles.navigationRow}>
      <ReportingTablePaddingCell classNames={classnames(styles.paddingCell)} />
      <ReportingTableEmptyCell
        colSpan={headerColumnsCount}
        cell={emptyCellData(false)}
        classNames={classnames(styles.emptyCell)}
      />
      <td className={classnames(styles.collapseButtonCell)}>
        <IconButton
          className={styles.chevronButton}
          styleType={ButtonStyle.Primary}
          onClick={onCollapseHandler}
          icon={collapsed ? 'chevron-right' : 'chevron-left'}
        />
      </td>
      <ReportingTablePaginationCell
        colSpan={collapsed ? dataColumnsCount : 3}
        periods={rowLength}
        classNames={styles.paginationCell}
        onPrevious={onPreviousHandler}
        onNext={onNextHandler}
        previousDisabled={previousDisabled}
        nextDisabled={nextDisabled}
      />
      <ReportingTablePaddingCell classNames={classnames(styles.paddingCell)} />
    </tr>
  );

  const renderBottomRow = () => (
    <tr className={styles.bottomRow}>
      <ReportingTablePaddingCell classNames={classnames(styles.paddingCell)} />
      <ReportingTableEmptyCell
        colSpan={headerColumnsCount}
        cell={emptyCellData(false)}
        classNames={classnames(styles.emptyCell)}
      />
      <ReportingTableSeparatorCell />
      <ReportingTableEmptyCell
        colSpan={collapsed ? dataColumnsCount : 3}
        cell={emptyCellData(false)}
        classNames={classnames(styles.emptyCell)}
      />
      <ReportingTablePaddingCell classNames={classnames(styles.paddingCell)} />
    </tr>
  );

  return (
    <table className={classnames(styles.templateReportingTable)}>
      {renderColGroup()}
      <thead ref={statusHeaderRef} className={styles.statusHeader}>
        {renderNavigationRow()}
        {activeRowsData.statusRows.map(
          (row: ReportingTableRow, rowIndex: number) => {
            return (
              <Fragment key={`Fragment-${row.type}-${rowIndex}`}>
                {renderRow(row, rowIndex)}
              </Fragment>
            );
          }
        )}
      </thead>
      <tbody>
        {activeRowsData.dataRows.map(
          (row: ReportingTableRow, rowIndex: number) => {
            return (
              <Fragment key={`Fragment-${row.type}-${row.id}`}>
                {renderRow(row, rowIndex)}
              </Fragment>
            );
          }
        )}
        {renderBottomRow()}
      </tbody>
    </table>
  );
};

export default ReportingTable;
