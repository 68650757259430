import {
  DataCellError,
  ForecastReportingInterval,
  ReportingTableCell,
  ReportingTableCellTypes,
  ReportingTableData,
  ReportingTableErrorMessages,
  ReportingTableRow,
  ReportingTableRowTypes,
  ReportingTableTemplateType,
  SubmitMultipleCellsPayload,
} from '../types';
import {Func} from '../types';
import {valueFormat} from './value-format';
import {MutableRefObject} from 'react';
import {TFunction} from 'i18next';

export const firstCharToLowercase = (str: string) =>
  `${str.charAt(0).toLowerCase()}${str.slice(1)}`;

export const emptyCellData = (after: boolean) => ({
  type: 'EmptyCell',
  id: 'EmptyCell',
  rowId: 'EmptyCell',
  columnId: 'EmptyCell',
  afterSeparator: after,
  data: null,
});

export const paginateRowsData = (
  rows: ReportingTableRow[],
  headerLength: number,
  dataLength: number,
  activeOffset: number,
  collapsed: boolean,
  reportingTableTemplateType: ReportingTableTemplateType = ReportingTableTemplateType.ActualsReportingTemplate,
  reportingInterval: ForecastReportingInterval | null = null,
  paginationStep: number = 1
) => {
  let collapsedColsCount: number = 6;
  let nonCollapsedColsCount: number = 3;
  let decrementFactor = collapsed ? collapsedColsCount : nonCollapsedColsCount;

  const getVisibleHeaderColumns = (collapsed: boolean): Array<number> => {
    let visHeaderColumns: Array<number> = [];
    if (
      reportingTableTemplateType ===
        ReportingTableTemplateType.ActualsReportingTemplate ||
      reportingTableTemplateType ===
        ReportingTableTemplateType.ForecastReportingTemplate
    ) {
      collapsed
        ? (visHeaderColumns = [0, 1, 4])
        : (visHeaderColumns = [0, 1, 2, 3, 4]);
    } else if (
      reportingTableTemplateType ===
      ReportingTableTemplateType.ESGReportingTemplate
    ) {
      collapsed
        ? (visHeaderColumns = [0, 1, 2, 5])
        : (visHeaderColumns = [0, 1, 2, 3, 4, 5]);
    }

    return visHeaderColumns;
  };
  const getVisibleDataColumns = (): Array<number> => {
    let visDataColumns = [];
    if (
      reportingTableTemplateType ===
        ReportingTableTemplateType.ActualsReportingTemplate ||
      reportingTableTemplateType ===
        ReportingTableTemplateType.ForecastReportingTemplate ||
      reportingTableTemplateType ===
        ReportingTableTemplateType.ESGReportingTemplate
    ) {
      if (
        reportingTableTemplateType ===
        ReportingTableTemplateType.ESGReportingTemplate
      ) {
        collapsedColsCount = 3;
        nonCollapsedColsCount = 3;
        decrementFactor = collapsed
          ? collapsedColsCount
          : nonCollapsedColsCount;
      }

      if (dataLength > collapsedColsCount) {
        for (let i = 0; i < decrementFactor; i++) {
          visDataColumns.push(headerLength + activeOffset - 1 - i);
        }
      }

      if (
        dataLength <= collapsedColsCount &&
        dataLength > nonCollapsedColsCount
      ) {
        if (collapsed) {
          for (let i = 0; i < collapsedColsCount; i++) {
            visDataColumns.push(headerLength + i);
          }
        } else {
          for (let i = 0; i < nonCollapsedColsCount; i++) {
            visDataColumns.push(headerLength - 1 + activeOffset - i);
          }
        }
      }

      if (dataLength <= nonCollapsedColsCount) {
        for (let i = 0; i < decrementFactor; i++) {
          visDataColumns.push(headerLength + i);
        }
      }
    }

    // if (
    //   reportingTableTemplateType ===
    //   ReportingTableTemplateType.ForecastReportingTemplate
    // ) {
    //   for (let i = 0; i < decrementFactor; i++) {
    //     visDataColumns.push(
    //       headerLength +
    //         (decrementFactor === nonCollapsedColsCount
    //           ? reportingInterval ===
    //             ForecastReportingInterval.QuarterlyForecast
    //             ? 1
    //             : 3
    //           : 0) +
    //         activeOffset -
    //         1 +
    //         i
    //     );
    //   }
    // }

    return visDataColumns;
  };
  const visibleHeaderColumns = getVisibleHeaderColumns(collapsed);
  const visibleDataColumns = getVisibleDataColumns();

  return rows.map((row: ReportingTableRow) => {
    const cells = row.cells.map(
      (cell: ReportingTableCell, index: number): ReportingTableCell => {
        return {
          ...cell,
          isVisible:
            visibleHeaderColumns.includes(index) ||
            visibleDataColumns.includes(index),
        };
      }
    );

    return {
      ...row,
      cells,
    };
  });
};

const handleKeyboardNavigationEvents = (
  event: KeyboardEvent,
  inputs: NodeListOf<HTMLInputElement>,
  index: number,
  collapsed: boolean,
  dataRows: ReportingTableRow[]
): void => {
  const collapsedColumnsCount: number = 6;
  const nonCollapsedColumnsCount: number = 3;
  const dataLength = getEditableColumnsCount(dataRows);

  if (
    event.key === 'Enter'
    // || event.key === 'ArrowDown'
  ) {
    event.preventDefault();
    const nextIndex =
      dataLength < collapsedColumnsCount
        ? index + dataLength
        : collapsed
        ? index + collapsedColumnsCount
        : index + nonCollapsedColumnsCount;
    if (nextIndex < inputs.length) {
      inputs[nextIndex].focus({
        preventScroll: true,
      });
    }
  }

  if (
    event.shiftKey &&
    event.key === 'Enter'
    // || event.key === 'ArrowUp'
  ) {
    event.preventDefault();
    const previousIndex =
      dataLength < collapsedColumnsCount
        ? index - dataLength
        : collapsed
        ? index - collapsedColumnsCount
        : index - nonCollapsedColumnsCount;
    if (previousIndex >= 0) {
      inputs[previousIndex].focus({
        preventScroll: false,
      });
    }
  }

  if (
    event.key === 'Tab'
    // || event.key === 'ArrowRight'
  ) {
    event.preventDefault();
    const nextIndex = index + 1;
    if (nextIndex < inputs.length) {
      inputs[nextIndex].focus();
    }
  }

  if (
    event.shiftKey &&
    event.key === 'Tab'
    // || event.key === 'ArrowLeft'
  ) {
    event.preventDefault();
    const previousIndex = index - 1;
    if (previousIndex >= 0) {
      inputs[previousIndex].focus();
    }
  }
};

export const getFocusNexElement = (
  querySelector: string,
  currentElement: any
) => {
  let hasNextElement: boolean = false;
  const elements = document.querySelectorAll(`.${querySelector}`);
  const currentIndex = Array.from(elements).indexOf(currentElement);
  const nextIndex = currentIndex + 1;

  if (nextIndex < elements.length) hasNextElement = true;

  const focusNextElement = () => {
    if (hasNextElement) {
      const nextElement = elements[nextIndex] as HTMLElement;
      nextElement.focus({
        preventScroll: true,
      });
    }
  };

  return {
    hasNextElement,
    focusNextElement,
  };
};

export const setupReportingTableKeyboardEvent = (
  tableQuerySelector: string,
  inputQuerySelector: string,
  inputErrorMessageClassName: string,
  collapsed: boolean,
  dataRows: ReportingTableRow[]
): Func<[], void> => {
  const reportingTableElement = document.querySelector(tableQuerySelector);
  const inputs: NodeListOf<HTMLInputElement> =
    document.querySelectorAll(inputQuerySelector);

  function handleTableKeyboardEvent(event: Event) {
    // Skip if not an KeyboardEvent
    if (!(event instanceof KeyboardEvent)) {
      return;
    }

    const inputElement = event.target as HTMLInputElement;
    // Check if event is fired by an input element
    if (inputElement.tagName.toLowerCase() === 'input') {
      const index = Array.from(inputs).indexOf(inputElement);
      const {hasNextElement, focusNextElement} = getFocusNexElement(
        inputErrorMessageClassName,
        inputElement
      );

      if (
        inputElement.classList.contains(inputErrorMessageClassName) &&
        hasNextElement
      ) {
        if (event.key === 'Enter' && hasNextElement) {
          event.preventDefault();
          event.stopPropagation();
          focusNextElement();
        }
      } else {
        handleKeyboardNavigationEvents(
          event,
          inputs,
          index,
          collapsed,
          dataRows
        );
      }
    }
  }

  // Add event listener from ReportingTable
  if (reportingTableElement) {
    reportingTableElement.addEventListener('keydown', handleTableKeyboardEvent);
  }

  // Return a cleanup function to remove event listener from ReportingTable
  return () => {
    if (reportingTableElement) {
      reportingTableElement.removeEventListener(
        'keydown',
        handleTableKeyboardEvent
      );
    }
  };
};

export const getCurrentColumnPeriod = (
  columnId: string,
  statusRows: ReportingTableRow[]
) => {
  const period = statusRows
    .find(row => row.type === ReportingTableRowTypes.StatusPeriodDateRow)
    ?.cells.find(cell => cell.id === columnId)?.data;

  return period?.value
    ? (valueFormat(period.value, period.format).value as string)
    : '';
};

export const getCurrentForecastInterval = (statusRows: ReportingTableRow[]) => {
  const interval = statusRows
    .find(row => row.type === ReportingTableRowTypes.StatusHeaderRow)
    ?.cells.find(
      cell =>
        cell.type === ReportingTableCellTypes.ForecastStatusCell &&
        !!cell?.data?.value &&
        cell?.data?.value?.isActivePeriod
    )?.data?.value?.interval;

  return interval ?? null;
};

export const getCurrentForecastPeriod = (statusRows: ReportingTableRow[]) => {
  const period = statusRows
    .find(row => row.type === ReportingTableRowTypes.StatusHeaderRow)
    ?.cells.find(
      cell =>
        cell.type === ReportingTableCellTypes.ForecastStatusCell &&
        !!cell?.data?.value &&
        cell?.data?.value?.isActivePeriod
    )?.data?.value?.reportingPeriod;

  if (period) {
    const startDate: string | number = valueFormat(period.start, period.format)
      .value as string;
    const endDate: string | number = valueFormat(period.end, period.format)
      .value as string;

    return `period ${startDate} - ${endDate}`;
  }

  return '';
};

export const getEditableColumnsCount = (
  dataRows: ReportingTableRow[]
): number => {
  let count: number = 0;
  dataRows
    .find(row => row.type === ReportingTableRowTypes.GroupRow)
    ?.cells.forEach(cell => {
      if (
        cell.type === ReportingTableCellTypes.DataCell &&
        cell.isVisible &&
        !!cell?.data && // We don't want to count fake columns
        (!cell?.data?.submitted ||
          (cell?.data?.submitted && !cell?.data?.isLocked))
      )
        count += 1;
    });

  return count;
};

// Add or remove error from cell state
export const updateInvalidCells = (
  rows: ReportingTableRow[],
  cellsWithError: string[] = [],
  columnId: string | null = null
): ReportingTableRow[] => {
  return rows.map((row: ReportingTableRow) => {
    const updatedCells = row.cells.map((cell: ReportingTableCell) => {
      if (
        !(
          cell.type === ReportingTableCellTypes.DataCell &&
          cell.columnId === columnId &&
          cell.data?.mandatory &&
          (cell.data.value === '' || cell.data.value === null)
        )
      ) {
        return {
          ...cell,
          data: !!cell?.data
            ? {
                ...cell.data,
                errorMessage: undefined,
              }
            : null,
        } as ReportingTableCell;
      }

      cellsWithError.push(cell.id);

      return {
        ...cell,
        data: !!cell?.data
          ? {
              ...cell.data,
              errorMessage: {
                cellId: cell.id,
                firstError: cellsWithError.length === 1,
                message: ReportingTableErrorMessages.isMandatory,
              },
            }
          : null,
      } as ReportingTableCell;
    });

    return {
      ...row,
      cells: updatedCells,
    } as ReportingTableRow;
  });
};

export const validateAllColumnsCells = (
  rows: ReportingTableRow[],
  cellsWithError: string[] = [],
  columnIds: string[] | null = null
): ReportingTableRow[] => {
  return rows.map((row: ReportingTableRow) => {
    const updatedCells = row.cells.map((cell: ReportingTableCell) => {
      if (
        !(
          cell.type === ReportingTableCellTypes.DataCell &&
          columnIds?.includes(cell.columnId) &&
          cell.data?.mandatory &&
          (cell.data.value === '' || cell.data.value === null)
        )
      ) {
        // console.warn(
        //   'in the guard clause', // Uncomment to use the console warning
        //   columnIds,
        //   cell.columnId,
        //   columnIds?.includes(cell.columnId)
        // );
        return {
          ...cell,
          data: !!cell?.data
            ? {
                ...cell.data,
                errorMessage: undefined,
              }
            : null,
        } as ReportingTableCell;
      }

      cellsWithError.push(cell.id);

      return {
        ...cell,
        data: !!cell?.data
          ? {
              ...cell.data,
              errorMessage: {
                cellId: cell.id,
                firstError: cellsWithError.length === 1,
                message: ReportingTableErrorMessages.isMandatory,
              },
            }
          : null,
      } as ReportingTableCell;

      // return {
      //   ...cell,
      //   data: !!cell?.data
      //     ? {
      //         ...cell.data,
      //         // errorMessage: undefined,
      //       }
      //     : null,
      // } as ReportingTableCell;
    });

    return {
      ...row,
      cells: updatedCells,
    } as ReportingTableRow;
  });
};

export const disableColumnSubmitButton = (
  rows: ReportingTableRow[],
  columnIds: Array<string>,
  disabled: boolean
): ReportingTableRow[] => {
  return rows.map((row: ReportingTableRow) => {
    if (row.type === ReportingTableRowTypes.StatusHeaderRow) {
      const cells = row.cells.map(cell => {
        if (columnIds.includes(cell.columnId)) {
          return {
            ...cell,
            disabled,
          };
        }
        return cell;
      });

      return {...row, cells: cells} as ReportingTableRow;
    }

    return row;
  });
};

export const disableForecastSubmitButton = (
  rows: ReportingTableRow[],
  disabled: boolean
): ReportingTableRow[] => {
  return rows.map((row: ReportingTableRow) => {
    if (row.type === ReportingTableRowTypes.StatusHeaderRow) {
      const cells = row.cells.map(cell => {
        if (
          cell.type === ReportingTableCellTypes.ForecastStatusCell &&
          !!cell?.data?.value
        ) {
          return {
            ...cell,
            disabled,
          };
        }
        return cell;
      });

      return {...row, cells: cells} as ReportingTableRow;
    }

    return row;
  });
};

export const updateDataCellErrorMessage = (
  rows: ReportingTableRow[],
  cellId: string,
  value: number | null,
  errorMessage: DataCellError | undefined
): ReportingTableRow[] => {
  return rows.map((row: ReportingTableRow) => {
    const cells = row.cells.map((cell: ReportingTableCell) => {
      if (
        cell.type === ReportingTableCellTypes.DataCell &&
        cell.id === cellId
      ) {
        return {
          ...cell,
          data: {
            ...cell.data,
            value,
            errorMessage,
          },
        } as ReportingTableCell;
      }

      return cell;
    });

    return {
      ...row,
      cells,
    } as ReportingTableRow;
  });
};

export const getActiveOffset = (data: ReportingTableData) => {
  return data.rowLength;
};

export const getVisibleColumnsIds = (
  data: ReportingTableRow[],
  justVisible: boolean
): Array<string> => {
  let columnIds: Array<string> = [];
  const firstGroupRow = data.find(
    (row: ReportingTableRow) => row.type === ReportingTableRowTypes.GroupRow
  );
  if (firstGroupRow) {
    firstGroupRow.cells.forEach((cell: ReportingTableCell): any => {
      if (justVisible) {
        if (
          cell.type === ReportingTableCellTypes.DataCell &&
          cell.isVisible &&
          !!cell?.data
        ) {
          columnIds.push(cell.columnId);
        }
      } else {
        if (cell.type === ReportingTableCellTypes.DataCell && !!cell?.data) {
          columnIds.push(cell.columnId);
        }
      }
    });
  }

  return columnIds;
};

export enum ParsedClipboardDataTypes {
  String = 'String',
  MultiLineString = 'MultiLineString',
  Number = 'Number',
  TSV = 'TSV',
}

// Utils for copy and paste functionality
type ParsedClipboardData = {
  originType: ParsedClipboardDataTypes;
  value: string | number | Array<Array<string | number | null>>;
};
export const parseClipboardData = (data: string): ParsedClipboardData => {
  const minColumnsForTsv = 2;
  const minRowsForTsv = 1;
  const isNumber = (value: string): boolean => {
    return !isNaN(Number(value.trim()));
  };

  // Split by newline to get potential rows
  const rows = data.split('\n').map(row => (row.trim() === '' ? null : row));

  // Check for a minimum number of rows
  if (rows.length >= minRowsForTsv) {
    // If any row splits into a valid number of columns, it's likely TSV
    const isAnyRowValidTSV = rows.some(row => {
      const columns = row ? row.split('\t') : [null];
      return (
        columns.length >= minColumnsForTsv ||
        (columns.length === 1 && rows.length > 1)
      );
    });

    if (isAnyRowValidTSV) {
      const tsvValue = rows.map((row: string | null) => {
        return row
          ? row.split('\t').map(cell => (cell === '' ? null : cell))
          : [null];
      });

      // Data is TSv
      return {
        originType: ParsedClipboardDataTypes.TSV,
        value: tsvValue,
      };
    }
  }

  // For data that isn't TSV
  if (rows.length === 1 && rows[0]) {
    if (!isNumber(rows[0])) {
      // Data is plain string
      return {
        originType: ParsedClipboardDataTypes.String,
        value: rows[0],
      };
    }

    // Data is number
    return {
      originType: ParsedClipboardDataTypes.Number,
      value: rows[0],
    };
  }

  // Data is multiline but not TSV
  return {
    originType: ParsedClipboardDataTypes.MultiLineString,
    value: data,
  };
};

export const pasteToMultipleCells = (
  clipboardData: any,
  cellId: string,
  dataRows: ReportingTableRow[],
  reportingTableTemplateType: ReportingTableTemplateType = ReportingTableTemplateType.ActualsReportingTemplate
): {
  dataRows: ReportingTableRow[];
  cellsPayload: SubmitMultipleCellsPayload;
  columnIds: Set<string>;
} => {
  const clipboardDataRowsLength = clipboardData.value?.length;
  const clipboardDataColumnsLength = clipboardData.value[0]?.length;
  const firstRowIdx = dataRows.findIndex(row =>
    row.cells.some(cell => cell.id === cellId)
  );
  const lastRowIdx = firstRowIdx + clipboardDataRowsLength - 1;
  const firstCellIdx = dataRows[firstRowIdx]?.cells.findIndex(
    cell => cell.id === cellId
  );
  const lastCellIdx = firstCellIdx + clipboardDataColumnsLength - 1;
  let countOfNonGroupRows = 0;
  let countOfSubmittedCells = 0;
  let cellsPayload: SubmitMultipleCellsPayload = {
    cells: [],
  };
  let columnIds: Set<string> = new Set();

  const updatedRowsData: ReportingTableRow[] = dataRows.map(
    (row, rowIdx): ReportingTableRow => {
      if (rowIdx >= firstRowIdx && rowIdx <= lastRowIdx + countOfNonGroupRows) {
        // Increase non group row counter
        if (row.type !== ReportingTableRowTypes.GroupRow) {
          countOfNonGroupRows += 1;
          return row;
        }

        const cells = row.cells.map((cell, cellIdx) => {
          if (
            cellIdx >= firstCellIdx &&
            cellIdx <= lastCellIdx + countOfSubmittedCells &&
            clipboardData.value[
              Math.abs(firstRowIdx + countOfNonGroupRows - rowIdx)
            ][Math.abs(firstCellIdx + countOfSubmittedCells - cellIdx)] !==
              undefined &&
            cell?.isVisible &&
            !!cell?.data
          ) {
            // Increase a submitted cell counter
            if (cell.data?.submitted && cell.data?.isLocked) {
              countOfSubmittedCells += 1;
              return cell;
            }

            // Get cell value from clipboard data
            const value =
              clipboardData.value[
                Math.abs(firstRowIdx + countOfNonGroupRows - rowIdx)
              ][Math.abs(firstCellIdx + countOfSubmittedCells - cellIdx)];

            // Collect multiple cells submission payload
            cellsPayload.cells.push({
              id: cell.id,
              value: value === null ? null : Number(value),
            });

            // Collect columnIds to disable submit buttons
            columnIds.add(cell.columnId);

            return {
              ...cell,
              data: {
                ...cell.data,
                value: {
                  isFromClipboard: true,
                  valueFromClipboard: isNaN(Number(value)) ? null : value,
                },
              },
            };
          }

          return cell;
        });

        // Reset the submitted cell counter
        countOfSubmittedCells = 0;

        return {
          ...row,
          cells,
        } as ReportingTableRow;
      }

      return row;
    }
  );

  // Reset the non group row counter
  countOfNonGroupRows = 0;

  return {
    dataRows: updatedRowsData,
    cellsPayload,
    columnIds,
  };
};

export const setupReportingTableCopyEvent = (
  element: HTMLElement,
  handleCopyShortcut: Func<[], void>
) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
      event.preventDefault();
      // Skip global copy handler if single cell is focused
      const target = event.target as HTMLElement;
      if (target.hasAttribute('data-single-cell-copy')) return;

      // Handle global copy shortcut
      handleCopyShortcut();
    }
  };

  element.addEventListener('keydown', onKeyDown);

  return () => {
    element.removeEventListener('keydown', onKeyDown);
  };
};

const createSelectionOverlay = (
  reportingTable: HTMLElement,
  selectionOverlayClass: string
) => {
  const selectionOverlay = document.createElement('div');
  selectionOverlay.tabIndex = 0;
  selectionOverlay.className = selectionOverlayClass;
  selectionOverlay.style.visibility = 'hidden';
  selectionOverlay.style.width = '0';
  selectionOverlay.style.height = '0';
  reportingTable.appendChild(selectionOverlay);

  const focus = () => {
    selectionOverlay.focus();
  };

  const hide = () => {
    selectionOverlay.style.visibility = 'hidden';
    selectionOverlay.style.width = '0';
    selectionOverlay.style.height = '0';
  };

  const show = (boundingRect: any) => {
    selectionOverlay.style.visibility = 'visible';
    selectionOverlay.style.top = `${boundingRect.top}px`;
    selectionOverlay.style.left = `${boundingRect.left}px`;
    selectionOverlay.style.width = `${boundingRect.width}px`;
    selectionOverlay.style.height = `${boundingRect.height}px`;

    selectionOverlay.focus();
  };

  // Handle click outside reporting table
  const handleClickOutside = (event: MouseEvent) => {
    // Check if the clicked element is outside the reportingTable
    if (!reportingTable.contains(event.target as Node)) {
      hide();
    }
  };

  // Add an event listener to the document to check if click is outside reportingTable and hide selectionOverlay if it is.
  document.addEventListener('click', handleClickOutside);

  return {
    element: selectionOverlay,
    removeEventListeners: () => {
      document.removeEventListener('click', handleClickOutside);
    },
    focus,
    hide,
    show,
  };
};

export const setupReportingTableMouseCellSelectionEvents = (
  reportingTable: HTMLElement,
  selectionClass: string,
  selectionOverlayClass: string,
  tableSelectionRef: MutableRefObject<string>
) => {
  let isSelecting = false;
  let startCell: HTMLElement;
  let endCell: HTMLElement;
  const cells = reportingTable.getElementsByTagName('input');
  const selectionOverlay = createSelectionOverlay(
    reportingTable,
    selectionOverlayClass
  );

  // Define mouse movement threshold
  const mouseMovementThreshold = 25;
  let initialMousePosition = {x: 0, y: 0};

  const handleMouseDown = (event: MouseEvent) => {
    const eventElement = event.target as HTMLElement;
    const isEventTargetInputElement =
      eventElement.tagName.toLowerCase() === 'input';
    // Ensure the target is an input element
    if (isEventTargetInputElement) isSelecting = true;

    startCell = eventElement; // Store the starting cell
    endCell = eventElement;

    // Store the initial mouse position
    initialMousePosition.x = event.clientX;
    initialMousePosition.y = event.clientY;

    // Deselect all cells when starting a new selection
    for (const cell of Array.from(cells)) {
      cell.classList.remove(selectionClass);
    }

    // Add selected class to the starting cell
    if (isEventTargetInputElement) startCell.classList.add(selectionClass);
  };

  const handleMouseUp = (event: MouseEvent) => {
    if (isSelecting && startCell !== endCell) {
      selectionOverlay.focus();
    }

    if (startCell === endCell) {
      selectionOverlay.hide();
    }

    isSelecting = false;

    // Reset the initial mouse position
    initialMousePosition.x = 0;
    initialMousePosition.y = 0;
  };

  const handleMouseMove = (event: MouseEvent) => {
    // Get the element directly under the mouse pointer
    const eventElement = document.elementFromPoint(
      event.clientX,
      event.clientY
    ) as HTMLElement;

    // The distance of the mouse movement
    const distanceMoved = Math.sqrt(
      Math.pow(event.clientX - initialMousePosition.x, 2) +
        Math.pow(event.clientY - initialMousePosition.y, 2)
    );

    // If the mouse is still within the initial cell and there's selected content, return early
    const inputElement = eventElement as HTMLInputElement;
    if (
      eventElement === startCell &&
      eventElement.tagName.toLowerCase() === 'input' &&
      (inputElement.selectionStart !== inputElement.selectionEnd ||
        distanceMoved < mouseMovementThreshold)
    )
      // The user is selecting text within the input, so return early
      return;

    if (
      !isSelecting ||
      !eventElement ||
      eventElement.tagName.toLowerCase() !== 'input' ||
      eventElement.dataset.fakeColumn === 'true'
    )
      return;

    endCell = eventElement; // Update the ending cell

    // Remove focus of first element if more than one element is selected
    if (startCell !== endCell) {
      selectionOverlay.focus();
    }

    const selectedCells: Array<HTMLInputElement> = [];

    // Loop through all cells and mark them as selected if they are within the selection area
    for (const cell of Array.from(cells)) {
      if (isBetween(cell, startCell, endCell)) {
        cell.classList.add(selectionClass);
        selectedCells.push(cell);
      } else {
        cell.classList.remove(selectionClass);
      }
    }

    const boundingRect = getBoundingRectForSelectedCells(selectedCells);
    selectionOverlay.show(boundingRect);

    // Convert and save selected data to tableSelectionRef
    tableSelectionRef.current = convertSelectedCellsToTSV();
  };

  const getBoundingRectForSelectedCells = (
    selectedCells: Array<HTMLInputElement>
  ) => {
    const boundingRects = selectedCells.map(cell =>
      cell.getBoundingClientRect()
    );
    const tableRect = reportingTable.getBoundingClientRect();

    const left =
      Math.min(...boundingRects.map(rect => rect.left)) - tableRect.left;
    const right =
      Math.max(...boundingRects.map(rect => rect.right)) - tableRect.left;
    const top =
      Math.min(...boundingRects.map(rect => rect.top)) - tableRect.top;
    const bottom =
      Math.max(...boundingRects.map(rect => rect.bottom)) - tableRect.top;

    return {
      left,
      top,
      width: right - left,
      height: bottom - top,
    };
  };

  const convertSelectedCellsToTSV = (): string => {
    const selectedValuesPerRow = getSelectedValuesFromRows();
    return selectedValuesPerRow
      .map(rowValues => rowValues.join('\t'))
      .join('\n');
  };

  const getSelectedValuesFromRows = (): string[][] => {
    const rows: any = document.querySelectorAll('table tr');
    const selectedValuesPerRow: string[][] = [];

    rows.forEach((row: HTMLTableRowElement) => {
      const selectedCells: any = Array.from(
        row.querySelectorAll(`input.${selectionClass}`)
      );

      const values = selectedCells.map((cell: HTMLInputElement) =>
        (cell.value || '').replaceAll(',', '')
      );

      if (selectedCells.length) {
        selectedValuesPerRow.push(values);
      }
    });

    return selectedValuesPerRow;
  };

  // Check if a cell is between two other cells
  const isBetween = (
    cell: HTMLElement,
    cell1: HTMLElement,
    cell2: HTMLElement
  ) => {
    const [x1, y1] = getCellCoordinates(cell1);
    const [x2, y2] = getCellCoordinates(cell2);
    const [x, y] = getCellCoordinates(cell);

    return (
      x >= Math.min(x1, x2) &&
      x <= Math.max(x1, x2) &&
      y >= Math.min(y1, y2) &&
      y <= Math.max(y1, y2)
    );
  };

  // Get the row and column index of a cell
  const getCellCoordinates = (cell: HTMLElement) => {
    const table = cell.closest('table');
    if (table) {
      const rows = Array.from(table.querySelectorAll('tr'));
      const rowIndex = rows.findIndex(row => row.contains(cell));
      if (rowIndex !== -1) {
        const columns = Array.from(rows[rowIndex].querySelectorAll('td, th'));
        const colIndex = columns.findIndex(col => {
          return col.getElementsByTagName('input')[0] === cell;
        });
        return [colIndex, rowIndex];
      }
    }

    return [0, 0];
  };

  const handleCopyShortcut = async () => {
    navigator.clipboard.writeText(tableSelectionRef.current).then(
      () => {
        console.warn('Content copied to clipboard');
      },
      error => {
        console.error('Failed to copy', {error});
      }
    );
  };

  // Attach event listeners to the table and cells
  reportingTable.addEventListener('mousedown', handleMouseDown);
  reportingTable.addEventListener('mouseup', handleMouseUp);
  reportingTable.addEventListener('mousemove', handleMouseMove);

  // Setup reporting table copy event
  const removeReportingTableCopyEvent = setupReportingTableCopyEvent(
    selectionOverlay.element,
    handleCopyShortcut
  );

  // Setup selectionOverlay ResizeObserver
  const selectionOverlayResizeObserver = new ResizeObserver(() => {
    // If the selectionOverlay is not visible, skip observing
    if (selectionOverlay.element.style.visibility !== 'visible') {
      return;
    }

    const selectedCells: Array<HTMLInputElement> = Array.from(
      reportingTable.querySelectorAll(`input.${selectionClass}`)
    );
    const boundingRect = getBoundingRectForSelectedCells(selectedCells);
    selectionOverlay.show(boundingRect);
  });

  selectionOverlayResizeObserver.observe(reportingTable);

  return () => {
    // Detach table and cells event listeners
    if (reportingTable) {
      reportingTable.removeEventListener('mousedown', handleMouseDown);
      reportingTable.removeEventListener('mouseup', handleMouseUp);
      reportingTable.removeEventListener('mousemove', handleMouseMove);
    }

    if (selectionOverlay) {
      removeReportingTableCopyEvent();
      selectionOverlay.removeEventListeners();
    }

    // Disconnect selectionOverlayResizeObserver
    selectionOverlayResizeObserver.disconnect();
  };
};

export const getReportingPeriodForConfirmation = (
  reportingData: ReportingTableData,
  activeSubmitColumnId: string | null,
  t: TFunction<'translation', undefined>,
  action: 'submit' | 'update' | 'cancel'
): string => {
  if (action === 'cancel') {
    return t('DataCollection.ReportingTable.Message.CancelChanges');
  }
  let reportingPeriod = getCurrentColumnPeriod(
    activeSubmitColumnId as string,
    reportingData.statusRows
  );

  // if (
  //   reportingData.type === ReportingTableTemplateType.ForecastReportingTemplate
  // ) {
  //   reportingPeriod = getCurrentForecastPeriod(reportingData.statusRows);
  //   return t(
  //     'Are you sure you want to submit the data for {{reportingPeriod}}?',
  //     {reportingPeriod}
  //   );
  // }

  if (reportingData.type === ReportingTableTemplateType.ESGReportingTemplate) {
    return t(
      'DataCollection.ReportingTable.ReportingPeriod.ESGReportingTemplate',
      {reportingPeriod}
    );
  }

  if (
    reportingData.type ===
      ReportingTableTemplateType.ActualsReportingTemplate ||
    reportingData.type === ReportingTableTemplateType.ForecastReportingTemplate
  ) {
    return t(
      'DataCollection.ReportingTable.ReportingPeriod.ActualsReportingTemplate',
      {reportingPeriod, action}
    );
  }

  return '';
};
