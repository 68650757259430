import {AuthenticationResult} from '@azure/msal-browser';

/**
 * Sets a cookie.
 *
 * @param name Name of cookie.
 * @param value Cookie value.
 * @param expiry Expiry date time.
 */
export function setCookie(name: string, value: string, expiry: Date) {
  document.cookie = `${name}=${value};expires=${expiry.toUTCString()};path=/`;
}

/**
 * Removes a cookie.
 *
 * @param name Name of the cookie to remove.
 */
export function removeCookie(name: string) {
  const expiry = new Date(0);
  document.cookie = `${name}=; expires=${expiry.toUTCString()};path=/`;
}

/**
 * Reads a cookie.
 *
 * @param name Name of cookie.
 */
export function getCookie(name: string) {
  const cookies = new Map(
    document.cookie.split('; ').map(item => {
      const parts = item.split('=');
      const [name, ...valueParts] = parts;
      const value = valueParts.join('=');
      return [name, value];
    })
  );
  return cookies.get(name);
}

export function manageAccessTokenCookie(authResult: AuthenticationResult) {
  const existingAccessToken = getCookie('LANTERN_ACCESS_TOKEN');
  const tokensMatch = authResult.accessToken === existingAccessToken;

  if (!existingAccessToken || !tokensMatch) {
    setCookie(
      'LANTERN_ACCESS_TOKEN',
      authResult.accessToken,
      authResult.expiresOn!
    );
  }
}
