import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {acquireAuthResult, msalInstance} from '../../';
import {
  CompanyMonitoring,
  FormStatus,
  HeaderMapping,
  MetricsChartDataItem,
  SelectOption,
} from '../../types';
import {
  deleteCompanyDetailsMonitoringMetricsChartConfig,
  getCompanyDetailsMonitoring,
  getCompanyDetailsMonitoringHistoricalData,
  getCompanyDetailsMonitoringMetricsChartConfig,
  GetMonitoringMetricsChartConfigResponse,
  MonitoringMetricsChartConfigPayload,
  saveCompanyDetailsMonitoringMetricsChartConfig,
  SaveMonitoringMetricsChartConfigResponse,
} from '../../api/getCompanyDetailsMonitoring';
import {errorHandlerAction, pageErrorHandlerAction} from '../../actions';
import {
  clearCompanyMonitoringData,
  deleteCompanyDetailsMonitoringMetricsChartConfigAction,
  exportCompanyDetailsMonitoringDataAction,
  getCompanyDetailsMonitoringHistoricalDataAction,
  getCompanyDetailsMonitoringMetricsChartConfigAction,
  resetCompanyDetailsMonitoringSavedChartConfig,
  saveCompanyDetailsMonitoringMetricsChartConfigAction,
  setCompanyDetailsMonitoringError,
  setCompanyDetailsMonitoringFormError,
  setCompanyDetailsMonitoringFormStatus,
  setCompanyDetailsMonitoringHistoricalData,
  setCompanyDetailsMonitoringIsExportPending,
  setCompanyDetailsMonitoringIsRequestPending,
  setCompanyMonitoringData,
  updateCompanyDetailsMonitoringSavedChartsList,
  updateSelectedMetricsConfiguration,
} from '../../reducers/companyDetailsMonitoringSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {responseErrorResolver} from '../../utils';
import {downloadBundledExports} from '../../utils/export';
import {
  getMetricsChartExportPayload,
  getMetricsTableExportPayload,
} from '../../ui-components/company-monitoring/monitoring-dashboard/MonitoringDashboardSettings';

export const companyDetailsMonitoringSaga =
  function* companyDetailsMonitoringSaga({payload}: any) {
    const id: string = payload?.match?.params?.id;
    if (id) {
      yield put(clearCompanyMonitoringData());
      try {
        const {accessToken} = yield call(acquireAuthResult, msalInstance);
        const response: CompanyMonitoring = yield call(
          getCompanyDetailsMonitoring,
          accessToken,
          id
        );
        yield put(setCompanyMonitoringData(response));
      } catch (err) {
        console.warn(err);
        yield put(pageErrorHandlerAction({error: err}));
      }
    }
  };

export const saveCompanyDetailsMonitoringMetricsChartConfigSaga =
  function* saveCompanyDetailsMonitoringMetricsChartConfigSaga({
    payload,
  }: PayloadAction<MonitoringMetricsChartConfigPayload>): any {
    yield call(companyDetailsMonitoringErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setCompanyDetailsMonitoringFormStatus(FormStatus.Pending));
      const response: SaveMonitoringMetricsChartConfigResponse = yield call(
        saveCompanyDetailsMonitoringMetricsChartConfig,
        accessToken,
        payload
      );
      yield put(
        updateCompanyDetailsMonitoringSavedChartsList(response.savedCharts)
      );
      yield put(setCompanyDetailsMonitoringFormStatus(FormStatus.Success));
    } catch (err: any) {
      console.warn(err);
      yield call(
        companyDetailsMonitoringResponseErrorHelperSaga,
        err,
        `Error ${
          payload.id ? 'updating' : 'saving'
        } chart configuration, please try again`
      );
    }
  };

export const getCompanyDetailsMonitoringMetricsChartConfigSaga =
  function* getCompanyDetailsMonitoringMetricsChartConfigSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setCompanyDetailsMonitoringIsRequestPending(true));
      const response: GetMonitoringMetricsChartConfigResponse = yield call(
        getCompanyDetailsMonitoringMetricsChartConfig,
        accessToken,
        payload.id
      );
      yield put(updateSelectedMetricsConfiguration(response.selectedMetrics));
      yield put(setCompanyDetailsMonitoringIsRequestPending(false));
    } catch (err: any) {
      console.warn(err);
      yield put(setCompanyDetailsMonitoringIsRequestPending(false));
      // handle GET error
      yield put(errorHandlerAction({error: err}));
    }
  };

export const deleteCompanyDetailsMonitoringMetricsChartConfigSaga =
  function* deleteCompanyDetailsMonitoringMetricsChartConfigSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    yield call(companyDetailsMonitoringErrorHelperSaga);
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setCompanyDetailsMonitoringFormStatus(FormStatus.Pending));
      yield call(
        deleteCompanyDetailsMonitoringMetricsChartConfig,
        accessToken,
        payload.id
      );
      // on delete ok
      const savedCharts: SelectOption[] | null = yield select(
        (state: RootState) =>
          state.companyDetails.companyMonitoring.data?.savedCharts
      );
      const filteredOutSavedCharts = savedCharts?.filter(
        item => item.value !== payload.id
      );
      yield put(resetCompanyDetailsMonitoringSavedChartConfig());
      if (filteredOutSavedCharts) {
        yield put(
          updateCompanyDetailsMonitoringSavedChartsList(filteredOutSavedCharts)
        );
      }
      yield put(setCompanyDetailsMonitoringFormStatus(FormStatus.Success));
    } catch (err: any) {
      console.warn(err);
      yield call(
        companyDetailsMonitoringResponseErrorHelperSaga,
        err,
        'Error deleting chart configuration, please try again'
      );
      // handle DELETE error
    }
  };

export const getCompanyDetailsMonitoringHistoricalDataSaga =
  function* getCompanyDetailsMonitoringHistoricalDataSaga({
    payload,
  }: PayloadAction<{id: string}>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setCompanyDetailsMonitoringIsRequestPending(true));
      const response: Pick<
        CompanyMonitoring,
        'metricsData' | 'metricsConfig' | 'savedCharts'
      > = yield call(
        getCompanyDetailsMonitoringHistoricalData,
        accessToken,
        payload.id
      );
      yield put(setCompanyDetailsMonitoringHistoricalData(response));
      yield put(setCompanyDetailsMonitoringIsRequestPending(false));
    } catch (err: any) {
      console.warn(err);
      yield put(setCompanyDetailsMonitoringIsRequestPending(false));
      // handle DELETE error
      yield put(errorHandlerAction({error: err}));
    }
  };

export const exportCompanyDetailsMonitoringDataSaga =
  function* exportCompanyDetailsMonitoringDataSaga({
    payload,
  }: PayloadAction<{
    companyName: string;
    data: MetricsChartDataItem[];
    headerMapping: HeaderMapping[];
  }>) {
    try {
      yield put(setCompanyDetailsMonitoringIsExportPending(true));
      const {companyName, data, headerMapping} = payload;
      const zipFileName = `${companyName.split(' ').join('')}_Monitoring`;

      yield call(downloadBundledExports, {
        zipFileName,
        tableExportPayload: getMetricsTableExportPayload(
          data,
          headerMapping,
          zipFileName
        ),
        imagesExportPayload: getMetricsChartExportPayload(zipFileName),
      });
      yield put(setCompanyDetailsMonitoringIsExportPending(false));
    } catch (err: any) {
      console.warn(err);
      yield put(setCompanyDetailsMonitoringIsExportPending(false));
    }
  };

const companyDetailsMonitoringErrorHelperSaga =
  function* companyDetailsMonitoringErrorHelperSaga() {
    const error: string | null = yield select(
      (state: RootState) => state.companyDetails.companyMonitoring.error
    );
    const formError: string | null = yield select(
      (state: RootState) => state.companyDetails.companyMonitoring.formError
    );
    if (error) {
      yield put(setCompanyDetailsMonitoringError(null));
    }
    if (formError) {
      yield put(setCompanyDetailsMonitoringFormError(null));
    }
  };

const companyDetailsMonitoringResponseErrorHelperSaga =
  function* companyDetailsMonitoringResponseErrorHelperSaga(
    err: any,
    placeholder: string
  ) {
    yield put(setCompanyDetailsMonitoringFormStatus(FormStatus.Ready));
    yield put(setCompanyDetailsMonitoringIsRequestPending(false));
    const {error, formError} = responseErrorResolver(err, placeholder);
    yield put(setCompanyDetailsMonitoringError(error));
    yield put(setCompanyDetailsMonitoringFormError(formError));
  };

export const companyDetailsMonitoringSagaWatcher =
  function* companyDetailsMonitoringSagaWatcher() {
    yield all([
      takeEvery(
        saveCompanyDetailsMonitoringMetricsChartConfigAction.type,
        saveCompanyDetailsMonitoringMetricsChartConfigSaga
      ),
      takeEvery(
        getCompanyDetailsMonitoringMetricsChartConfigAction.type,
        getCompanyDetailsMonitoringMetricsChartConfigSaga
      ),
      takeEvery(
        deleteCompanyDetailsMonitoringMetricsChartConfigAction.type,
        deleteCompanyDetailsMonitoringMetricsChartConfigSaga
      ),
      takeEvery(
        getCompanyDetailsMonitoringHistoricalDataAction.type,
        getCompanyDetailsMonitoringHistoricalDataSaga
      ),
      takeEvery(
        exportCompanyDetailsMonitoringDataAction.type,
        exportCompanyDetailsMonitoringDataSaga
      ),
    ]);
  };
